import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextArea from "../../../components/textArea";
import NumericInput from '../../../components/numericInput';
import Modal from "../../../components/modal";
import Switch from '../../../components/switch';
import TextInput from '../../../components/textInput';

import './settings.css';
import SelectInput from '../../../components/selectInput';
import QualificationSelect from './qualificationSelect';
import QualificationInput from './qualificationInput';

const BonuseSettings = ({ bonuses, updateBonuses, definitions, customerTypes, ranks, periods }) => {
  const [activeItem, setActiveItem] = useState();
  const [qualInput, setQualInput] = useState();
  const [showModal, setShowModal] = useState();
  const [deleteId, setDeleteId] = useState();
  const [showDelModal, setShowDelModal] = useState();

  useEffect(() => {
    if (activeItem) {
      let index = bonuses.findIndex((el) => el.uiId == activeItem.uiId);

      if (index > -1) {
        bonuses[index] = activeItem;
      } else {
        bonuses.push(activeItem);
      }

      updateBonuses(bonuses);
    }
  }, [activeItem])

  const deleteBonus = () => {
    let index = bonuses.findIndex((el) => el.uiId == deleteId);
    if (index > -1) {
      bonuses.splice(index, 1)
      updateBonuses(bonuses);
    }

    setActiveItem();
    setShowDelModal(false);
  }

  const handleHideDelModal = () => setShowDelModal(false);
  const handleShowDelModel = (uiId) => {
    setDeleteId(uiId);
    setShowDelModal(true);
  }

  const handleHideModal = () => setShowModal(false);
  const handleShowModal = () => {
    setQualInput({ allRanks: true });
    setShowModal(true);
  }

  const handleQualChange = (name, value) => {
    setQualInput(values => {
      return { ...values, [name]: value };
    });
  }

  const handleQualSubmit = () => {
    setActiveItem(item => {
      var key = qualInput?.qualKey ?? '';
      var keyVal = qualInput?.value ?? 0;

      if (item.bonusType == 1) {
        if (key == "Rank" && qualInput.allRanks) {
          item.generationBonuses = ranks.map((rank) => { return { qualification: { key: 'Rank', value: rank.id }, percents: [{ generation: 4, value: 0 }] } })
        } else {
          var gen = item.generationBonuses.find((el) => el.qualification?.key == key && (el.qualification?.value == keyVal || el.qualification?.volume == keyVal));
          if (!gen) {
            item.generationBonuses.push({ qualification: { key: key, value: keyVal }, percents: [{ generation: 4, value: 0 }] });
          }
        }
      } else if (item.bonusType == 2) {
        if (key == "Rank" && qualInput.allRanks) {
          item.binaryBonuses = ranks.map((rank) => { return { qualification: { key: 'Rank', value: rank.id }, percents: [{ generation: 4, value: 0 }] } })
        } else {
          var bGen = item.binaryBonuses.find((el) => el.qualification?.key == key && (el.qualification?.value == keyVal || el.qualification?.volume == keyVal));
          if (!bGen) {
            item.binaryBonuses.push({ qualification: { key: key, value: keyVal }, percents: [{ generation: 4, value: 0 }] });
          }
        }
      } else if (item.bonusType == 3) {
        var percent = item.poolBonus[0]?.percent ?? 0;
        if (key == "Rank" && qualInput.allRanks) {
          item.poolBonus = ranks.map((rank) => { return { percent: percent, pointsKey: null, points: 1, qualification: { key: 'Rank', value: rank.id } } })
        } else {
          var pGen = item.poolBonus.find((el) => el.qualification?.key == key && (el.qualification?.value == keyVal || el.qualification?.volume == keyVal));
          if (!pGen) {
            item.poolBonus.push({ percent: percent, pointsKey: null, points: 1, qualification: { key: key, value: keyVal } });
          }
        }
      }

      return { ...item };
    });
    setShowModal(false);
  }

  const handleHide = () => {
    setActiveItem();
  }

  const showItem = (uiId) => {
    let bonus = bonuses.find((el) => el.uiId == uiId);

    if (bonus) {
      var bonusType = 1;
      if (bonus.generationBonuses && bonus.generationBonuses.length > 0) bonusType = 1;
      if (bonus.binaryBonuses && bonus.binaryBonuses.length > 0) bonusType = 2;
      if (bonus.poolBonus && bonus.poolBonus.length > 0) bonusType = 3;
      if (bonus.rollingBonuses && bonus.rollingBonuses.length > 0) bonusType = 4;
      bonus.bonusType = bonusType;
    } else {
      bonus = { uiId: crypto.randomUUID(), periodId: periods[0]?.incrementType, id: '', bonusType: 1, name: '', description: '', volumeKey: '', index: 0 }
      bonus.generationBonuses = [{ qualification: undefined, percents: [{ generation: 1, percent: 0 }] }];
      bonus.binaryBonuses = [];
      bonus.poolBonus = [];
      bonus.qualifications = [];
    }
    setActiveItem(bonus);
  }

  const handleBonusTypeChange = (name, value) => {
    setActiveItem(values => {

      values.generationBonuses = [];
      values.binaryBonuses = [];
      values.poolBonus = [];

      if (value === "1") values.generationBonuses = [{ qualification: undefined, percents: [{ generation: 1, percent: 0 }] }];
      if (value === "2") values.binaryBonuses = [];
      if (value === "3") values.poolBonus = [{ percent: 0, pointsKey: null, points: 1, qualification: null }];

      return { ...values, [name]: value };
    });
  }

  const handleChange = (name, value) => {
    setActiveItem(values => {
      return { ...values, [name]: value };
    });
  }

  const handleRequirementChange = (uiId, name, value) => {
    setActiveItem(item => {
      var qual = item.qualifications.find((el) => el.uiId == uiId);
      if (name == 'eq') {
        if (value == "E") {
          qual.value = qual.volume;
          qual.volume = undefined;
        } else {
          qual.volume = qual.value;
          qual.value = undefined;
        }
      } else {
        qual[name] = value;
      }

      return { ...item };
    });
  }

  const handleRequirementAdd = () => {
    setActiveItem(item => {
      if (item.qualifications == undefined) {
        item.qualifications = [];
      }

      item.qualifications.push({
        value: 0,
        uiId: crypto.randomUUID()
      });
      return { ...item };
    });
  }

  const handleRequirementRemove = (uiId) => {
    setActiveItem(item => {
      var index = item.qualifications.findIndex((el) => el.uiId == uiId);
      if (index > -1) {
        item.qualifications.splice(index, 1);
      }

      return { ...item };
    });
  }

  const handleAddLevel = () => {
    let generations = activeItem.generationBonuses?.map((b) => b.percents.map((a) => a.generation)).flat().filter((value, index, array) => array.indexOf(value) === index);
    let nextGen = Math.max(...generations) + 1;
    if (nextGen > 0) {
      setActiveItem(item => {
        item.generationBonuses[0].percents.push({
          generation: nextGen,
          percent: 0
        });
        return { ...item };
      });
    }
  }

  const handlePoolPercentChange = (name, value) => {
    setActiveItem((item) => {
      const updatedPoolBonus = item.poolBonus.map((bonusItem) => ({
        ...bonusItem,
        percent: value,
      }));

      return { ...item, poolBonus: updatedPoolBonus };
    });
  }

  const handlePoolValueChange = (key, keyVal, name, value) => {
    setActiveItem(item => {
      var gen = item.poolBonus.find((el) => el.qualification?.key == key && (el.qualification?.value == keyVal || el.qualification?.volume == keyVal));
      const index = item.poolBonus.indexOf(gen);
      item.poolBonus[index] = { ...gen, [name]: value };

      return { ...item };
    });
  }

  const handleGenerationValueChange = (g, key, keyVal, value) => {
    setActiveItem(item => {
      var gen = item.generationBonuses.find((el) => el.qualification?.key == key && (el.qualification?.value == keyVal || el.qualification?.volume == keyVal));
      var percent = gen.percents.find((el) => el.generation == g);
      if (percent) {
        percent.percent = value;
      } else {
        gen.percents.push({ generation: g, percent: value });
      }

      return { ...item };
    });
  }

  const handleBinaryValueChange = (key, keyVal, name, value) => {
    setActiveItem(item => {
      var gen = item.binaryBonuses.find((el) => el.qualification?.key == key && (el.qualification?.value == keyVal || el.qualification?.volume == keyVal));
      const index = item.binaryBonuses.indexOf(gen);
      item.binaryBonuses[index] = { ...gen, [name]: value };

      return { ...item };
    });
  }

  const handleGenerationQualRemove = (key, keyVal) => {
    setActiveItem(item => {
      if (item.bonusType == 1) {
        item.generationBonuses = item.generationBonuses.filter((el) => el.qualification?.key != key || (el.qualification?.value != keyVal && el.qualification?.volume != keyVal));
      } else if (item.bonusType == 2) {
        item.binaryBonuses = item.binaryBonuses.filter((el) => el.qualification?.key != key || (el.qualification?.value != keyVal && el.qualification?.volume != keyVal));
      } else if (item.bonusType == 3) {
        item.poolBonus = item.poolBonus.filter((el) => el.qualification?.key != key || (el.qualification?.value != keyVal && el.qualification?.volume != keyVal));
      }
      return { ...item };
    });
  }

  if (activeItem) {
    let generations = activeItem.generationBonuses?.map((b) => b.percents.map((a) => a.generation)).flat().filter((value, index, array) => array.indexOf(value) === index);
    let gens = [];
    for (let x = 0; x <= Math.max(...generations); x++) {
      gens.push(x);
    }

    return <>
      <div className="card-header">
        <h3 className="card-title">Bonus Details</h3>
        <div className="card-actions btn-actions">
          <button className="btn btn-action" onClick={handleHide}>
            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
          </button>
        </div>
      </div>
      <div className="card-body">


        <div className="row mb-3">
          <div className="col-2">
            <label className="form-label">Index</label>
            <NumericInput name="index" value={activeItem.index} onChange={handleChange} />
          </div>
          <div className="col-6">
            <label className="form-label">Name</label>
            <TextInput name="name" value={activeItem.name} onChange={handleChange} />
          </div>
          <div className="col-2">
            <label className="form-label">Code</label>
            <TextInput name="id" value={activeItem.id} onChange={handleChange} />
          </div>
          <div className="col-2">
            <label className="form-label">Period</label>
            <SelectInput name="periodId" value={activeItem.periodId} onChange={handleChange}>
              {periods && periods.map((p) => {
                return <option key={p.id}>{p.id}</option>
              })}
            </SelectInput>
          </div>
        </div>

        <div className="">
          <label className="form-label">Description</label>
          <TextArea name="description" value={activeItem.description} onChange={handleChange} />
        </div>

      </div>
      <div className="card-body">

        <div className="row mb-3">
          <div className="col-3">
            <label className="form-label">Bonus Type</label>
            <SelectInput name="bonusType" value={activeItem.bonusType} onChange={handleBonusTypeChange} >
              <option value="1">Generation Bonus</option>
              <option value="2">Binary Bonus</option>
              <option value="3">Pool Bonus</option>
            </SelectInput>
          </div>

          <div className="col-3">
            <label className="form-label">Bonus Volume</label>
            <QualificationSelect definitions={definitions} bonuses={bonuses} showBonuses={activeItem.bonusType == "1"} periodId={activeItem.periodId} hideRank={true} name="volumeKey" value={activeItem.volumeKey} onChange={handleChange} />
          </div>

          {activeItem.bonusType == 1 && <>
            <div className="col-3">
              <label className="form-label">Compression Term</label>
              <QualificationSelect emptyText="No compression" definitions={definitions} periodId={activeItem.periodId} name="compressionKey" value={activeItem.compressionKey} onChange={handleChange} >
                <option value="LevelRequirement">Level Requirement</option>
              </QualificationSelect>
            </div>
            <div className="col-3">
              <label className="form-label">Compression Value</label>
              <QualificationInput qualKey={activeItem.compressionKey} ranks={ranks} name="compressionValue" value={activeItem.compressionValue} onChange={handleChange} />
            </div>
          </>}

          {activeItem.bonusType == 3 && <>
            <div className="col-3">
              <label className="form-label">Volume Percent for Pool</label>
              <NumericInput name="percent" value={activeItem.poolBonus[0]?.percent} onChange={handlePoolPercentChange} />
            </div>
          </>}

        </div>
      </div>
      {activeItem.bonusType == 1 && <>
        <div className="card-body">
          <h2 className="form-label mb-3">Level Percents</h2>
          <div className="mb-3">
            <table className="xclTable" cellPadding="0" cellSpacing="0">
              <thead>
                <th className="text-center">Requirement</th>
                {gens.map((g) => {
                  return <th key={g} className="text-center">Level {g}</th>
                })}
                <th></th>
              </thead>
              <tbody>
                {activeItem.generationBonuses && activeItem.generationBonuses
                  .sort((a, b) => (a.qualification?.value > b.qualification?.value) ? 1 : -1)
                  .map((gBonus) => {
                    var key = `${gBonus.qualification?.key}_${gBonus.qualification?.value}`;
                    return <tr key={key}>
                      <td className="label">
                        {gBonus.qualification?.key.toLowerCase() == 'rank' && ranks.find((el) => el.id == gBonus.qualification?.value)?.name}
                        {gBonus.qualification?.key.toLowerCase() != 'rank' && `${(gBonus.qualification?.key ?? '') != '' ? gBonus.qualification?.key : 'N/A'}: ${(gBonus.qualification?.value ?? 0) > 0 ? gBonus.qualification?.value : ''}`}
                      </td>

                      {gens.map((g) => {
                        var gValue = gBonus.percents.find((el) => el.generation == g)?.percent ?? 0;
                        return <td key={g}>
                          <NumericInput className="none" value={gValue > 0 ? gValue : ''} onChange={(name, value) => handleGenerationValueChange(g, gBonus.qualification?.key, gBonus.qualification?.value, value)} />
                        </td>
                      })}

                      <td>
                        <div className="btn-list flex-nowrap">
                          <button className="btn btn-action" onClick={() => handleGenerationQualRemove(gBonus.qualification?.key, gBonus.qualification?.value)}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash" width="40" height="40" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M4 7l16 0"></path><path d="M10 11l0 6"></path><path d="M14 11l0 6"></path><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path></svg>
                          </button>
                        </div>
                      </td>

                    </tr>
                  })}
              </tbody>
            </table>
          </div>
          <div className="mb-3">
            <div className="btn-list">
              <button className="btn" onClick={handleShowModal}>
                New Requirement
              </button>
              <button className="btn" onClick={handleAddLevel}>
                New Level
              </button>
            </div>
          </div>
        </div>
      </>}
      {activeItem.bonusType == 2 && <>
        <div className="card-body">
          <h2 className="form-label mb-3">Level Percents</h2>
          <div className="mb-3">
            <table className="xclTable" cellPadding="0" cellSpacing="0">
              <thead>
                <th className="text-center">Requirement</th>
                <th>Greater Percent</th>
                <th>Lesser Percent</th>
                <th>Greater Batch Size</th>
                <th>Lesser Batch Size</th>
                <th>Cap</th>
                <th></th>
              </thead>
              <tbody>
                {activeItem.binaryBonuses && activeItem.binaryBonuses
                  .sort((a, b) => (a.qualification?.value > b.qualification?.value) ? 1 : -1)
                  .map((gBonus) => {
                    var key = `${gBonus.qualification?.key}_${gBonus.qualification?.value}`;
                    return <tr key={key}>
                      <td className="label">
                        {gBonus.qualification?.key.toLowerCase() == 'rank' && ranks.find((el) => el.id == gBonus.qualification?.value).name}
                        {gBonus.qualification?.key.toLowerCase() != 'rank' && `${gBonus.qualification?.key ?? 'N/A'}: ${gBonus.qualification?.value ?? ''}`}
                      </td>

                      <td>
                        <NumericInput className="none" name="greaterLegPercent" value={gBonus.greaterLegPercent} onChange={(name, value) => handleBinaryValueChange(gBonus.qualification?.key, gBonus.qualification?.value, name, value)} />
                      </td>
                      <td>
                        <NumericInput className="none" name="lesserLegPercent" value={gBonus.lesserLegPercent} onChange={(name, value) => handleBinaryValueChange(gBonus.qualification?.key, gBonus.qualification?.value, name, value)} />
                      </td>
                      <td>
                        <NumericInput className="none" name="greaterBatchSize" value={gBonus.greaterBatchSize} onChange={(name, value) => handleBinaryValueChange(gBonus.qualification?.key, gBonus.qualification?.value, name, value)} />
                      </td>
                      <td>
                        <NumericInput className="none" name="lesserBatchSize" value={gBonus.lesserBatchSize} onChange={(name, value) => handleBinaryValueChange(gBonus.qualification?.key, gBonus.qualification?.value, name, value)} />
                      </td>
                      <td>
                        <NumericInput className="none" name="bonusCap" value={gBonus.bonusCap} onChange={(name, value) => handleBinaryValueChange(gBonus.qualification?.key, gBonus.qualification?.value, name, value)} />
                      </td>
                      <td>
                        <div className="btn-list flex-nowrap">
                          <button className="btn btn-action" onClick={() => handleGenerationQualRemove(gBonus.qualification?.key, gBonus.qualification?.value)}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash" width="40" height="40" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M4 7l16 0"></path><path d="M10 11l0 6"></path><path d="M14 11l0 6"></path><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path></svg>
                          </button>
                        </div>
                      </td>
                    </tr>
                  })}
              </tbody>
            </table>
          </div>
          <div className="mb-3">
            <div className="btn-list">
              <button className="btn" onClick={handleShowModal}>
                New Requirement
              </button>
            </div>
          </div>
        </div>
      </>}
      {activeItem.bonusType == 3 &&
        <div className="card-body">
          <h2 className="form-label mb-3">Level Percents</h2>
          <div className="mb-3">
            <table className="xclTable" cellPadding="0" cellSpacing="0">
              <thead>
                <th className="text-center">Requirement</th>
                <th>Points</th>
                <th></th>
              </thead>
              <tbody>
                {activeItem.poolBonus && activeItem.poolBonus
                  .sort((a, b) => (a.qualification?.value ?? '_' > b.qualification?.value ?? '_') ? 1 : -1)
                  .map((gBonus) => {
                    var key = `${gBonus.qualification?.key ?? '_'}_${gBonus.qualification?.value}`;
                    return <tr key={key}>
                      <td className="label">
                        {gBonus.qualification?.key.toLowerCase() == 'rank' && ranks.find((el) => el.id == gBonus.qualification?.value).name}
                        {gBonus.qualification?.key.toLowerCase() != 'rank' && `${gBonus.qualification?.key ?? 'N/A'}: ${gBonus.qualification?.value ?? ''}`}
                      </td>

                      <td>
                        <NumericInput className="none" name="points" value={gBonus.points} onChange={(name, value) => handlePoolValueChange(gBonus.qualification?.key, gBonus.qualification?.value, name, value)} />
                      </td>
                      <td>
                        <div className="btn-list flex-nowrap">
                          <button className="btn btn-action" onClick={() => handleGenerationQualRemove(gBonus.qualification?.key, gBonus.qualification?.value)}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash" width="40" height="40" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M4 7l16 0"></path><path d="M10 11l0 6"></path><path d="M14 11l0 6"></path><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path></svg>
                          </button>
                        </div>
                      </td>
                    </tr>
                  })}
              </tbody>
            </table>
          </div>
          <div className="mb-3">
            <div className="btn-list">
              <button className="btn" onClick={handleShowModal}>
                New Requirement
              </button>
            </div>
          </div>
        </div >
      }
      {activeItem.bonusType == 4 && <span>Has rollingBonuses</span>}


      <div className="card-body">
        <h2 className="form-label">General Requirements</h2>
      </div>
      <div className="table-responsive">
        <table className="table card-table table-vcenter text-nowrap">
          <thead>
            <tr>
              <th className="">Term</th>
              <th className="">Required</th>
              <th className="w-1"></th>
            </tr>
          </thead>
          <tbody>
            {/* <tr>
              <td>
                <input className="form-control" value="Customer Type" disabled={true} />
              </td>
              <td>
                <select className="form-select" name="customerTypeId" value={activeItem.customerTypeId} onChange={(e) => handleChange(e.target.name, e.target.value)}>
                  {customerTypes && customerTypes.map((cType) => {
                    return <option key={cType.value} value={cType.value}>{cType.description}</option>
                  })}
                </select>
              </td>
              <td></td>
            </tr> */}

            {activeItem.qualifications && activeItem.qualifications.map((qual) => {
              return <tr key={qual.key}>
                <td>
                  <QualificationSelect definitions={definitions} periodId={activeItem.periodId} value={qual.key} name="key" onChange={(name, value) => handleRequirementChange(qual.uiId, name, value)} />
                </td>
                <td>
                  <QualificationInput ranks={ranks} customerTypes={customerTypes} qualKey={qual.key} value={qual.value ?? qual.volume ?? ''} name="value" onChange={(name, value) => handleRequirementChange(qual.uiId, name, value)} />
                </td>
                <td>
                  <div className="btn-list flex-nowrap">
                    <button className="btn btn-action" onClick={() => handleRequirementRemove(qual.uiId)}>
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash" width="40" height="40" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M4 7l16 0"></path><path d="M10 11l0 6"></path><path d="M14 11l0 6"></path><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path></svg>
                    </button>
                  </div>
                </td>
              </tr>
            })}
          </tbody>
        </table>
      </div>
      <div className="card-footer bg-transparent mt-auto">
        <div className="btn-list justify-content-end">
          <button className="btn" onClick={() => handleRequirementAdd()}>
            New Requirement
          </button>
        </div>
      </div>


      <Modal showModal={showModal} onHide={handleHideModal}>
        <div className="modal-header">
          <h5 className="modal-title">New Level Requirement</h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-12 mb-3">
              <label className="form-label">Term</label>
              <QualificationSelect emptyText="No requirement" definitions={definitions} periodId={activeItem.periodId} value={qualInput?.qualKey ?? ''} name="qualKey" onChange={handleQualChange} />
            </div>
            {qualInput?.qualKey == "Rank" &&
              <div className="col-12 mb-3">
                <Switch title="Add requirement for all ranks" name="allRanks" value={qualInput.allRanks} onChange={handleQualChange} />
              </div>
            }
            {(qualInput?.qualKey != "Rank" || !qualInput.allRanks) && (qualInput?.qualKey ?? '') != '' &&
              <div className="col-12 mb-3">
                <label className="form-label">Required</label>
                <QualificationInput ranks={ranks} customerTypes={customerTypes} qualKey={qualInput.qualKey} value={qualInput?.value ?? ''} name="value" onChange={handleQualChange} />
              </div>
            }
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn" data-bs-dismiss="modal">Close</button>
          <button type="button" className="btn btn-primary" onClick={handleQualSubmit}>Add Requirement</button>
        </div>
      </Modal>
    </>
  }


  return <>
    <div className="card-header">
      <h2 className="mb-o">Commissions &amp; Bonuses</h2>
    </div>

    <div className="table-responsive">
      <table className="table card-table table-vcenter text-nowrap datatable">
        <thead>
          <tr>
            <th>Index</th>
            <th>Code</th>
            <th>Name</th>
            <th>Volume</th>
            <th>Max Payout</th>
            <th>Description</th>
            <th>Period</th>
            <th className="w-1"></th>
          </tr>
        </thead>
        <tbody>
          {bonuses && bonuses.sort((a, b) => (a.index > b.index) ? 1 : -1).map((bonus) => {
            return <tr key={bonus.id}>
              <td>{bonus.index}</td>
              <td>{bonus.id}</td>
              <td>{bonus.name}</td>
              <td>{bonus.volumeKey}</td>
              <td>42%</td>
              <td className="demo"><span>{bonus.description}</span></td>
              <td>{bonus.periodId}</td>
              <td>
                <div className="btn-list flex-nowrap">
                  <button className="btn" onClick={() => { showItem(bonus.uiId) }}>
                    Edit
                  </button>
                  <button className="btn btn-icon" onClick={() => handleShowDelModel(bonus.uiId)}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><line x1="4" y1="7" x2="20" y2="7"></line><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path></svg>
                  </button>
                </div>
              </td>
            </tr>
          })}

        </tbody>
      </table>
    </div>
    <div className="card-footer bg-transparent mt-auto">
      <div className="btn-list justify-content-end">
        <button className="btn btn-outline-primary" onClick={() => { showItem('') }} >
          New Bonus
        </button>
      </div>
    </div>

    <Modal showModal={showDelModal} size="sm" onHide={handleHideDelModal}>
      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      <div className="modal-body py-4">
        <div className="modal-title">Are you sure?</div>
        <div className="text-muted">Do you really want to remove this bonus? It cannot be undone.</div>
      </div>

      <div className="modal-footer">
        <div className="w-100">
          <div className="row">
            <div className="col">
              <button type="button" className="btn w-100" data-bs-dismiss="modal">Cancel</button>
            </div>
            <div className="col">
              <button type="button" className="btn btn-danger w-100" onClick={deleteBonus} >Delete Bonus</button>
            </div>
          </div>
        </div>
      </div>
    </Modal>

  </>
};

export default BonuseSettings;

BonuseSettings.propTypes = {
  bonuses: PropTypes.any.isRequired,
  updateBonuses: PropTypes.func.isRequired,
  definitions: PropTypes.any.isRequired,
  customerTypes: PropTypes.any.isRequired,
  ranks: PropTypes.any.isRequired,
  periods: PropTypes.any.isRequired
}
