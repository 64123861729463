import React, { Children } from 'react';
import PropTypes from 'prop-types';

const QualificationSelect = ({ className, name, value, onChange, disabled, emptyText, definitions, bonuses, showBonuses, periodId, hideRank, children }) => {

  const handleChange = (event) => {
    var name = event.target.name;
    var value = event.target.value;
    onChange(name, value);
  };

  const options = (
    <>
      {!hideRank && <option value="Rank">Rank</option>}
      {!hideRank && <option value="CustType">Customer Type</option>}
      {definitions && definitions
        .sort((a, b) => a.index - b.index)
        .filter((p) => periodId === undefined || p.periodId === periodId)
        .map((def) => (
          <option key={def.valueId} value={def.valueId}>
            {def.name} ({def.valueId})
          </option>
        ))}
    </>
  );

  return <select className={className ?? 'form-select'} name={name} value={value} disabled={disabled} onChange={handleChange}>
    <option value="" >{emptyText ?? 'N / A'}</option>
    {Children.map(children, child =>
      <>{child}</>
    )}
    {showBonuses ? (<>
      <optgroup label="Volume Terms">
        {options}
      </optgroup>
      <optgroup label="Bonus Match">
        {bonuses && bonuses.map((bonus) => {
          return <option key={bonus.id} value={bonus.id}>{bonus.name} ({bonus.id})</option>
        })}
      </optgroup>
    </>
    ) : (
      options
    )}
  </select>;
}

export default QualificationSelect;



QualificationSelect.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
  emptyText: PropTypes.string.isRequired,
  definitions: PropTypes.any.isRequired,
  bonuses: PropTypes.any,
  showBonuses: PropTypes.any,
  periodId: PropTypes.string.isRequired,
  hideRank: PropTypes.bool.isRequired,
  children: PropTypes.any
}
