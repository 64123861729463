import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../components/modal';
import TextInput from '../../../components/textInput'
import DateTimeInput from '../../../components/dateTimeInput'
import ScenarioStep from './scenarioStep';
import ScenarioResults from './scenarioResults';
import SelectInput from '../../../components/selectInput';
import ScenarioValueInput from './scenarioValueInput';
import LocalDate from '../../../util/LocalDate';

const Scenarios = ({ tests, updateTests, customerTypes, highPeriodDate }) => {
  const [activeItem, setActiveItem] = useState();
  const [activeStep, setActiveStep] = useState();
  const [activeResult, setActiveResult] = useState();
  const [deleteId, setDeleteId] = useState();
  const [showDelModal, setShowDelModal] = useState();

  useEffect(() => {
    if (activeItem) {
      var index = tests.findIndex((t) => t.uiId === activeItem.uiId);

      if (index !== -1) {
        tests[index] = activeItem;
      } else {
        tests.push(activeItem);
      }

      updateTests(tests);
    }
  }, [activeItem])

  const handleChange = (name, value) => {
    setActiveItem((item) => ({ ...item, status: '', failedMessage: '', [name]: value }));
  }

  const handleHideDetail = () => { setActiveResult(); }
  const handleShowResults = (uiId) => {
    var item = tests.find((t) => t.uiId === uiId) ?? { uiId: crypto.randomUUID(), steps: [] };
    setActiveResult(item.resultData);
  }

  const handleHideStepModal = () => setActiveStep();
  const handleHideDelModal = () => setShowDelModal(false);
  const handleShowDelModel = (rankId) => {
    setDeleteId(rankId);
    setShowDelModal(true);
  }

  const deleteItem = () => {
    let index = tests.findIndex((el) => el.uiId == deleteId);
    if (index > -1) {
      tests.splice(index, 1)
      updateTests(tests);
    }

    setActiveItem();
    setShowDelModal(false);
  }

  const handleHide = () => setActiveItem();
  const handleShow = (uiId) => {
    var item = tests.find((t) => t.uiId === uiId) ?? { uiId: crypto.randomUUID(), steps: [] };
    setActiveItem(item);
  }

  const handleCopyScenario = (uiId) => {
    var item = tests.find((t) => t.uiId === uiId);

    const deepCopy = (obj) => {
      if (obj && typeof obj === 'object') {
        if (Array.isArray(obj)) {
          return obj.map(deepCopy);
        } else {
          const copiedObj = { ...obj, uiId: crypto.randomUUID() };
          if (copiedObj.steps) {
            copiedObj.steps = copiedObj.steps.map(deepCopy);
          }
          return copiedObj;
        }
      }
      return obj;
    };

    if (item) {

      let copy = deepCopy(item);
      tests.push(copy);
      setActiveItem(copy);
    }
  }

  const handleNewStep = (pUiId) => {
    if (pUiId) {
      setActiveStep({ uiId: crypto.randomUUID(), pUiId: pUiId, stepType: "AddNode", steps: [] });
    } else {
      setActiveStep({ uiId: crypto.randomUUID(), stepType: "AddNode", steps: [] });
    }
  }

  const handleEditStep = (uiId) => {
    var findResult = findId(activeItem.steps, uiId);
    setActiveStep(findResult.item);
  }

  const handleStepChange = (name, value) => {
    setActiveStep(t => ({ ...t, [name]: value }));
  }

  const genrateDefaultText = (step) => {
    let name = step?.name;
    if ((step?.name ?? '') == '') {
      name = step?.stepType;
    }

    let nodeId = step?.nodeId;
    if ((step?.nodeId ?? '') == '') {
      var item = findId(tests, step?.uiId);
      if (item) {
        nodeId = genrateDefaultText(item.parent).nodeId;
      } else {
        if (step?.pUiId) {
          var pItem = findId(tests, step?.pUiId);
          nodeId = genrateDefaultText(pItem.item).nodeId;
        }
      }
    }

    let stepDate = step?.date;
    if ((step?.date ?? '') == '') {
      var item2 = findId(tests, step?.uiId);
      if (item2) {
        stepDate = genrateDefaultText(item2.parent).date;
      } else {
        if (step?.pUiId) {
          var pItem2 = findId(tests, step?.pUiId);
          stepDate = genrateDefaultText(pItem2.item).date;
        }
      }
    }

    let description = step?.description;
    if ((step?.description ?? '') == '') {

      /*
       <option value="AddDownline">Add Downline</option>
                */
      if (step?.stepType === 'AddNode') {
        var customerType = (customerTypes.find((c) => c.value == step.value1)?.description) ?? step.value1;
        description = `New ${customerType} under ${step.value2}`;
      } else if (step?.stepType === 'AddSource') {
        description = `Add ${step.value2} ${step.value1}`;
      } else if (step?.stepType === 'AddDownline') {
        const value1 = parseFloat(step.value1);
        const value2 = parseFloat(step.value2);
        const customerType2 = (customerTypes.find((c) => c.value == step.value3)?.description) ?? step.value3;
        if (!isNaN(value1) && !isNaN(value2)) {
          const result = totalItems(value1, value2);
          description = `Add ${result} ${customerType2}s, ${value1} nodes for ${value2} generations.`;
        }
        //description = `Add ${step.value1 & step.value2} ${customerType2}s. ${step.value1} wide and ${step.value2} deep.`;
      } else if (step?.stepType === 'AddPlacement') {
        description = `Place under ${step.value1} ${step.value3 ? `on ${step.value3} leg ` : ''}in ${step.value2} tree`;
      } else if (step?.stepType === 'VerifyValue') {
        description = `Verify ${step.value1} is ${step.value2}`;
      } else if (step?.stepType === 'VerifyBonus') {
        description = `Verify ${step.value1} level ${step.value2} is ${step.value3}`;
      } else {
        description = `${step?.stepType} ${nodeId}`;
      }
    }

    return {
      name: name,
      nodeId: nodeId,
      description: description,
      date: stepDate ?? highPeriodDate
    };
  }

  const handleSaveStep = () => {
    setActiveItem((currentItem) => {
      // Find the index of the step to edit in the currentItem's steps array
      const editItem = findId(currentItem.steps, activeStep.uiId);

      if (editItem) {
        // If the step exists, update it
        editItem.array[editItem.index] = activeStep;
      } else {
        // Insert the step into a new item
        if (activeStep.pUiId) {
          // If there's a parent ID, find the parent item in the currentItem's steps array
          const findResult = findId(currentItem.steps, activeStep.pUiId);

          if (findResult) {
            // If the parent item is found, add the step to its steps array
            findResult.item.steps.push(activeStep);
          }
        } else {
          // If there's no parent ID, add the step directly to the currentItem's steps array
          currentItem.steps.push(activeStep);
        }
      }

      // Return a new object to trigger a re-render
      return { ...currentItem };
    });

    // Clear the active step after saving
    setActiveStep();
  };


  const handleDeleteStep = (uiId) => {
    setActiveItem((currentItem) => {
      var findResult = findId(activeItem.steps, uiId);
      if (findResult) {
        findResult.array.splice(findResult.index, 1)
      }

      return { ...currentItem }
    });
  }

  const handleCopyStep = (uiId) => {
    setActiveItem((currentItem) => {
      const findResult = findId(currentItem.steps, uiId);

      const deepCopy = (obj) => {
        if (obj && typeof obj === 'object') {
          if (Array.isArray(obj)) {
            return obj.map(deepCopy);
          } else {
            const copiedObj = { ...obj, uiId: crypto.randomUUID() };
            if (copiedObj.steps) {
              copiedObj.steps = copiedObj.steps.map(deepCopy);
            }
            return copiedObj;
          }
        }
        return obj;
      };

      if (findResult) {
        const copy = deepCopy(findResult.item);
        findResult.array.push(copy); //.splice(findResult.index + 1, 0, copy);
      }

      return { ...currentItem };
    });
  };

  if (activeResult)
  {
    return <>
    <div className="card-header">
        <h3 className="card-title">Scenario Result Data - Processed on <LocalDate dateString={activeResult.processDate} /></h3>
        <div className="card-actions btn-actions">
          <button className="btn btn-action" onClick={handleHideDetail}>
            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
          </button>
        </div>
      </div>
      <div className="card-body">
        <ScenarioResults results={activeResult} />
      </div>
    </>
  }

  if (activeItem) {
    return <>
      <div className="card-header">
        <h3 className="card-title">Scenario Details</h3>
        <div className="card-actions btn-actions">
          <button className="btn btn-action" onClick={handleHide}>
            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
          </button>
        </div>
      </div>
      <div className="card-body">
        <div className="row mb-3">
          <div className="col-4">
            <label className="form-label">Scenario Name</label>
            <TextInput name="name" value={activeItem?.name ?? ''} onChange={handleChange} />
          </div>
          <div className="col-8">
            <label className="form-label">Description</label>
            <TextInput name="description" value={activeItem?.description ?? ''} onChange={handleChange} />
          </div>
        </div>
      </div>
      <table className="table card-table table-vcenter text-nowrap datatable">
        <thead>
          <tr>
            <th className="w-25">Step</th>
            <th>Node Id</th>
            <th>Description</th>
            <th>Date</th>
            <th>Status</th>
            <th className="w-1"></th>
          </tr>
        </thead>
        <tbody>
          {activeItem.steps && activeItem.steps.map((s) => {
            return <ScenarioStep key={s.uiId} step={s} parentPeriodId={1} genrateDefaultText={genrateDefaultText} onNewStep={handleNewStep} onCopyStep={handleCopyStep} onDeleteStep={handleDeleteStep} onEditStep={handleEditStep} />
          })}
        </tbody>
      </table>
      <div className="card-footer bg-transparent mt-auto">
        <div className="btn-list justify-content-end">
          <button className="btn btn-outline-primary" onClick={() => { handleNewStep() }} >
            New Step
          </button>
        </div>
      </div>

      <Modal showModal={activeStep} onHide={handleHideStepModal}>
        <div className="modal-header">
          <h5 className="modal-title">Scenario Step</h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-4 mb-3">
              <label className="form-label">Action</label>
              <SelectInput name="stepType" value={activeStep?.stepType ?? ''} onChange={handleStepChange}>
                <option value="AddNode">Add Node</option>
                <option value="AddSource">Add Source</option>
                <option value="AddDownline">Add Downline</option>
                <option value="AddPlacement">Place Node</option>
                <option value="VerifyValue">Verify Value</option>
                <option value="VerifyBonus">Verify Bonus</option>
              </SelectInput>
            </div>
            <div className="col-3 mb-3">
              <label className="form-label">Node Id</label>
              <TextInput name="nodeId" placeholder={genrateDefaultText(activeStep).nodeId} value={activeStep?.nodeId ?? ''} onChange={handleStepChange} />
            </div>
            <div className="col-5 mb-3">
              <label className="form-label">Date</label>
              <DateTimeInput name="date" placeholder={genrateDefaultText(activeStep).date} value={activeStep?.date ?? ''} onChange={handleStepChange} />
            </div>
          </div>
          <div className="row mb-3">
            <ScenarioValueInput step={activeStep} stepType={activeStep?.stepType} onChange={handleStepChange} />
            {/* <div className="col">
              <label className="form-label">Customer Type</label>
              <TextInput name="valueId" value={activeStep?.valueId ?? ''} onChange={handleStepChange} />
            </div>
            <div className="col">
              <label className="form-label">Upline Node</label>
              <TextInput name="value" value={activeStep?.value ?? ''} onChange={handleStepChange} />
            </div> */}
          </div>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-4 mb-3">
              <label className="form-label">Name</label>
              <TextInput name="name" placeholder={genrateDefaultText(activeStep).name} value={activeStep?.name ?? ''} onChange={handleStepChange} />
            </div>
            <div className="col-8 mb-3">
              <label className="form-label">Description</label>
              <TextInput name="description" placeholder={genrateDefaultText(activeStep).description} value={activeStep?.description ?? ''} onChange={handleStepChange} />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn" data-bs-dismiss="modal">Close</button>
          <button type="button" className="btn btn-primary" onClick={handleSaveStep} >Save</button>
        </div>
      </Modal >
    </>
  }

  return <>
    <div className="card-header">
      <h2 className="mb-0">Test Scenarios</h2>
    </div>
    <div className="tabl">
      <table className="table card-table table-vcenter text-nowrap datatable">
        <thead>
          <tr>
            <th>Scenario</th>
            <th>Description</th>
            <th>Processed Date</th>
            <th>Status</th>
            <th className="w-1"></th>
          </tr>
        </thead>
        <tbody>
          {tests && tests.map((t) => {
            return <tr key={t.name}>
              <td>{t.name}</td>
              <td>{t.description}</td>
              <td>{t.resultData?.processDate &&
                <button className="btn btn-link" onClick={() => { handleShowResults(t.uiId) }}> <LocalDate dateString={t.resultData?.processDate} /> </button>
              }</td>
              <td>
                {t.childErrors > 0 && <><span className="badge badge-outline text-red">{t.childErrors} Failed</span></>}
              </td>
              <td>
                <div className="btn-list flex-nowrap">
                  <button className="btn" onClick={() => { handleShow(t.uiId) }}>
                    Edit
                  </button>
                  <span className="dropdown">
                    <a href="#" className="btn btn-default btn-icon" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="19" r="1"></circle><circle cx="12" cy="5" r="1"></circle></svg>
                    </a>
                    <div className="dropdown-menu dropdown-menu-end">
                      <button className="dropdown-item" onClick={() => handleCopyScenario(t.uiId)}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon dropdown-item-icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M8 8m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z"></path><path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2"></path></svg>
                        Copy Scenario
                      </button>
                      <button className="dropdown-item text-danger" onClick={() => handleShowDelModel(t.uiId)}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon dropdown-item-icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><line x1="4" y1="7" x2="20" y2="7"></line><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path></svg>
                        Delete Scenario
                      </button>
                    </div>
                  </span>
                </div>
              </td>
            </tr>
          })}
        </tbody>
      </table>
    </div>
    <div className="card-footer bg-transparent mt-auto">
      <div className="btn-list justify-content-end">
        <button className="btn btn-outline-primary" onClick={() => { handleShow() }} >
          New Scenario
        </button>
      </div>
    </div>

    <Modal showModal={showDelModal} size="sm" onHide={handleHideDelModal}>
      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      <div className="modal-body py-4">
        <div className="modal-title">Are you sure?</div>
        <div className="text-muted">Do you really want to remove this scenario? It cannot be undone.</div>
      </div>

      <div className="modal-footer">
        <div className="w-100">
          <div className="row">
            <div className="col">
              <button type="button" className="btn w-100" data-bs-dismiss="modal">Cancel</button>
            </div>
            <div className="col">
              <button type="button" className="btn btn-danger w-100" onClick={deleteItem} >Delete Scenario</button>
            </div>
          </div>
        </div>
      </div>
    </Modal>

  </>
};

export default Scenarios;

function findId(arr, targetId, parent) {
  for (let index = 0; index < arr.length; index++) {
    const obj = arr[index];

    if (obj?.uiId === targetId) {
      return { item: obj, array: arr, index, parent };
    }

    if (obj.steps && Array.isArray(obj.steps)) {
      const result = findId(obj.steps, targetId, obj);
      if (result) {
        return result;
      }
    }
  }

  return null;
}

function totalItems(branchingFactor, generations) {
  if (generations === 0) {
    return 0; // Each leaf node represents one item
  } else {
    // Calculate the total items in the subtree and multiply by the branching factor
    var genValue = Math.pow(branchingFactor, generations);
    return genValue + totalItems(branchingFactor, generations - 1);
  }
}

Scenarios.propTypes = {
  tests: PropTypes.any.isRequired,
  updateTests: PropTypes.func.isRequired,
  customerTypes: PropTypes.any.isRequired,
  highPeriodDate: PropTypes.string.isRequired
}
