import React, { Children } from 'react';
import PropTypes from 'prop-types';



const CheckBox = ({ name, value, onChange, disabled, errorText, children }) => {

    const handleChange = (event) => {

        var name = event.target.name;
        var value = event.target.checked;

        onChange(name, value);
    };

    const formClass = errorText ? `form-check is-invalid` : 'form-check';
    const inputClass = errorText ? `form-check-input is-invalid` : 'form-check-input';

    return <>
        <label className={formClass}>
            <input type="checkbox" className={inputClass} name={name} checked={value ?? false} disabled={disabled ?? false} onChange={handleChange} />
            {Children.map(children, child =>
                <>{child}</>
            )}
        </label>
        {errorText && <div className="invalid-feedback">{errorText}</div>}
    </>
}

export default CheckBox;

CheckBox.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    children: PropTypes.any.isRequired,
    errorText: PropTypes.string
}
