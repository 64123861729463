import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LocalDate from '../../../util/LocalDate';

const ScenarioStep = ({ step, level, genrateDefaultText, onNewStep, onCopyStep, onDeleteStep, onEditStep }) => {
  const [expanded, setExpanded] = useState(false);

  level = level ?? 0;
  const handleExpand = () => {
    setExpanded(!expanded);
  }

  return <>
    <tr key={step.uiId}>
      <td>
        {step.steps?.length > 0 && <span className="cursor-pointer" style={{ marginLeft: `${level * 25}px` }} onClick={handleExpand} >
          {!expanded && <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M10 18l6 -6l-6 -6v12"></path>
          </svg>}
          {expanded && <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M6 10l6 6l6 -6h-12"></path>
          </svg>}
        </span>}
        {!(step.steps?.length > 0) && <span className="" style={{ marginLeft: `${20 + (level * 25)}px` }} />}
        {genrateDefaultText(step).name}
      </td>
      <td>
        {(step?.nodeId ?? '') !== '' ? step.nodeId : <small className="text-muted">{genrateDefaultText(step).nodeId}</small>}
      </td>
      <td>
        {genrateDefaultText(step).description}
      </td>
      <td>
        {(step?.date ?? '') !== '' ? <LocalDate dateString={step.date} /> : <small className="text-muted"><LocalDate dateString={genrateDefaultText(step).date} /></small>}
      </td>
      <td>
        {step.childErrors > 0 && <><span className="badge badge-outline text-red">{step.childErrors} Failed</span></>}
        {step.error && <><span className="badge bg-red-lt">{step.error}</span></>}
      </td>
      <td>
        <div className="btn-list flex-nowrap">
          <button className="btn" onClick={() => { onEditStep(step.uiId) }}>
            Edit
          </button>
          <span className="dropdown">
            <a href="#" className="btn btn-default btn-icon" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="19" r="1"></circle><circle cx="12" cy="5" r="1"></circle></svg>
            </a>
            <div className="dropdown-menu dropdown-menu-end">
              <button className="dropdown-item" onClick={() => onNewStep(step.uiId)}>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon dropdown-item-icon" width="40" height="40" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M9 12h6"></path><path d="M12 9v6"></path><path d="M6 19a2 2 0 0 1 -2 -2v-4l-1 -1l1 -1v-4a2 2 0 0 1 2 -2"></path><path d="M18 19a2 2 0 0 0 2 -2v-4l1 -1l-1 -1v-4a2 2 0 0 0 -2 -2"></path></svg>
                New Step
              </button>
              <button className="dropdown-item" onClick={() => onCopyStep(step.uiId)}>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon dropdown-item-icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M8 8m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z"></path><path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2"></path></svg>
                Copy Step
              </button>
              <button className="dropdown-item" onClick={() => onDeleteStep(step.uiId)}>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon dropdown-item-icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><line x1="4" y1="7" x2="20" y2="7"></line><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path></svg>
                Delete Step
              </button>
            </div>
          </span>
        </div>
      </td>
    </tr>
    {expanded && step.steps && step.steps.map((s) => {
      return <ScenarioStep key={s.uiId} level={(level) + 1} step={s} genrateDefaultText={genrateDefaultText} onNewStep={onNewStep} onCopyStep={onCopyStep} onDeleteStep={onDeleteStep} onEditStep={onEditStep} />
    })}
  </>
}

export default ScenarioStep;


ScenarioStep.propTypes = {
  step: PropTypes.any.isRequired,
  level: PropTypes.number.isRequired,
  genrateDefaultText: PropTypes.func.isRequired,
  onNewStep: PropTypes.func.isRequired,
  onCopyStep: PropTypes.func.isRequired,
  onDeleteStep: PropTypes.func.isRequired,
  onEditStep: PropTypes.func.isRequired
}