import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFetch } from '../../../hooks/useFetch'
import { SendRequest } from '../../../hooks/usePost'
import Modal from '../../../components/modal';
import SelectInput from '../../../components/selectInput';
import TextInput from '../../../components/textInput';
import MoneyOutLogs from './moneyOutLogs';

const MoneyOutCard = ({ environmentId }) => {
  const [showLogs, setShowLogs] = useState(false);
  const [connect, setConnect] = useState();
  const { error, data, refetch } = useFetch(`/api/v1/MoneyOut/${environmentId}`);

  const handleHideLogs = () => setShowLogs(false);
  const handleViewLogs = () => {
    setShowLogs(true);
  }

  const handleChange = (name, value) => {
    if (name == "serviceType" || name == "url") {
      setConnect(v => ({ ...v, [name]: value, error: null }));
    } else {
      setConnect(v => ({
        ...v,
        headerKeys: {
          ...v.headerKeys,
          [name]: value
        },
        error: null
      }));
    }
  }

  const handleShow = () => {
    var url = (data.url ?? '') == '' ? 'https://pillars.paymenture.com/api/v1/batches' : data.url;
    setConnect({ serviceType: data.serviceType, headerKeys: data.headerKeys, url: url, status: false });
  }

  const handleConnect = () => {
    setConnect(v => ({ ...v, status: true, error: null }));

    SendRequest("PUT", `/api/v1/MoneyOut/${environmentId}`, connect, () => {
      setConnect();
      refetch();
    }, (error, code) => {
      if (code === 400) {
        const parsedError = JSON.parse(error);
        setConnect((v) => ({
          ...v,
          status: false,
          error: {
            url: parsedError.Url,
            publicKey: parsedError.PublicKey,
            privateKey: parsedError.PrivateKey,
          },
        }));
      } else {
        setConnect((v) => ({
          ...v,
          status: false,
          error: { general: error },
        }));
      }
    })
  }

  if (error) return `Error! ${error}`;


  return <>
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">
          Commission Payment Integration
        </h3>
        <div className="card-actions">
          <button onClick={handleShow} className="btn w-100 btn-icon">
            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-settings" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z"></path><path d="M9 12a3 3 0 1 0 6 0a3 3 0 0 0 -6 0"></path></svg>
          </button>
        </div>
      </div>
      <div className="card-body">
        <div className="mb-3">
          <div className="datagrid">
            <dl className="row">
              <dt className="col-6">Payment Service</dt>
              <dd className="col-6 text-end">{data?.serviceType ?? 'Loading...'}</dd>
            </dl>
          </div>
        </div>
      </div>
      <div className="card-footer">
        <div className="d-flex">
          <button className="btn btn-outline-default btn-sm ms-auto" onClick={handleViewLogs}>Service Logs</button>
        </div>
      </div>
    </div>

    <Modal showModal={showLogs} size="lg" onHide={handleHideLogs}>
      <div className="modal-header">
        <h5 className="modal-title">Payment Connection Logs</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="">
        {showLogs && <MoneyOutLogs environmentId={environmentId} />}
      </div>
      <div className="modal-footer">
        <button className="btn btn-default" data-bs-dismiss="modal">Close</button>
      </div>
    </Modal>

    <Modal showModal={connect != undefined} onHide={() => setConnect()} >
      <div className="modal-header">
        <h5 className="modal-title">Commission Payment Connection</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <div className="mb-3">
          <label className="form-label">Payment Service</label>
          <SelectInput name="serviceType" value={connect?.serviceType} onChange={handleChange} >
            <option value="Pillars">Pillars Internal</option>
            <option value="Paymenture">Paymenture</option>
            <option value="Custom">Custom</option>
          </SelectInput>
          {connect?.serviceType == "Paymenture" && <small className="form-hint mt-2 ecom-woo">
            If you need help with any of these fields, please contanct <a href="https://5567634.hs-sites.com/en/knowledge-base/kb-tickets/new" target="_blank" rel="noreferrer">Paymenture Support</a> for assistance.
          </small>}
        </div>
        {connect?.serviceType == "Paymenture" && <>
          <div className="mb-3">
            <label className="form-label">Service Url</label>
            <TextInput name="url" value={connect?.url} errorText={connect?.error?.publicKey} onChange={handleChange} />
          </div>
          <div className="mb-3">
            <label className="form-label">Paymenture User</label>
            <TextInput name="x-user" value={connect?.headerKeys["x-user"]} errorText={connect?.error?.publicKey} onChange={handleChange} />
          </div>
          <div className="mb-3">
            <label className="form-label">Paymenture Token</label>
            <TextInput name="x-token" value={connect?.headerKeys["x-token"]} errorText={connect?.error?.privateKey} onChange={handleChange} />
          </div>
          <div className="mb-3">
            <label className="form-label">Paymenture Client Id</label>
            <TextInput name="x-clientid" value={connect?.headerKeys["x-clientid"]} errorText={connect?.error?.privateKey} onChange={handleChange} />
          </div>
          <div className="mb-3">
            <label className="form-label">Paymenture Company Id</label>
            <TextInput name="x-companyid" value={connect?.headerKeys["x-companyid"]} errorText={connect?.error?.privateKey} onChange={handleChange} />
          </div>
        </>}

        {connect?.error?.general && <>
          <div className="alert alert-danger" role="alert">
            <h4 className="alert-title">Unable to connect to Payment Service</h4>
            <div className="text-muted">{connect.error.general}</div>
          </div>
        </>}

      </div>
      <div className="modal-footer">
        <button className="btn btn-link link-secondary" data-bs-dismiss="modal">Cancel</button>
        {connect?.status && <button className="btn btn-primary ms-auto"><span className="spinner-border spinner-border-sm me-2" role="status"></span> Connecting</button>}
        {!connect?.status && <button className="btn btn-primary ms-auto" onClick={handleConnect}>Connect</button>}
      </div>

    </Modal>

  </>
};

export default MoneyOutCard;

MoneyOutCard.propTypes = {
  environmentId: PropTypes.string.isRequired
}

