import React from 'react';
import PropTypes from 'prop-types';

const NumericInput = ({ className, name, value, onChange, disabled }) => {

    const handleChange = (event) => {
        var name = event.target.name;
        var value = Number(event.target.value);
        onChange(name, value);
    };

    return <input type="number" className={className ?? "form-control"} name={name} value={value > 0 ? value : ''} disabled={disabled} autoComplete='off' onChange={handleChange}></input>;
}

export default NumericInput;


NumericInput.propTypes = {
    className: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    disabled: PropTypes.bool.isRequired,
}
