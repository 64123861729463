import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../components/modal';

import "./scenarioResults.css";
import LocalDate from '../../../util/LocalDate';
import SelectInput from '../../../components/selectInput';


const ScenarioResults = ({ results }) => {
  const [activeValues, setActiveValues] = useState();
  const [activeBonuses, setActiveBonuses] = useState();
  const [activePeriods, setActivePeriods] = useState();
  const [selectedPeriods, setSelectedPeriods] = useState();
  const trees = [...new Set(results.nodes.map(item => item.tree))];
  const periods = [...new Set(results.values.map(item => item.periodName))];

  // Create a map to organize nodes by their uplineId
  const nodesMap = results.nodes.reduce((acc, node) => {
    const uplineId = node.uplineId || '0';
    const tree = node.tree;

    if (!acc[tree]) {
      acc[tree] = {};
    }

    if (!acc[tree][uplineId]) {
      acc[tree][uplineId] = [];
    }

    acc[tree][uplineId].push(node);

    // Sort the nodes array alphabetically by the 'leg' property
    acc[tree][uplineId].sort((a, b) => {
      return a.leg.localeCompare(b.leg);
    });

    return acc;
  }, {});

  const handleShow = (nodeId) => {
    var valueArray = results.values.filter((el) => el.nodeId == nodeId);
    setActiveValues(valueArray);
    var bonusArray = results.bonuses.filter((el) => el.nodeId == nodeId);
    setActiveBonuses(bonusArray);

    // Combine PeriodDates from both arrays
    var allPeriods = [
      ...valueArray.map(el => ({ periodDate: el.periodDate, periodName: el.periodName })),
      ...bonusArray.map(el => ({ periodDate: el.periodDate, periodName: el.periodName }))
    ];

    // Filter out duplicates
    var distinctPeriods = allPeriods.filter((period, index, self) =>
      index === self.findIndex((p) => (
        p.periodDate === period.periodDate && p.periodName === period.periodName
      ))
    );
    setActivePeriods(distinctPeriods);

    var firstPeriodDates = distinctPeriods.reduce((acc, period) => {
      if (!(period.periodName in acc) || period.periodDate < acc[period.periodName]) {
        acc[period.periodName] = period.periodDate;
      }
      return acc;
    }, {});
    setSelectedPeriods(firstPeriodDates);
  }

  const renderNestedList = (tree, uplineId) => {
    const children = nodesMap[tree] && nodesMap[tree][uplineId] || [];

    if (children.length === 0) {
      return null;
    }

    return (
      <ul key={uplineId}>
        {children.map((child) => (
          <li key={child.nodeId}>
            {renderNode(child)}
            {renderNestedList(tree, child.nodeId)}
          </li>
        ))}
      </ul>
    );
  };

  const renderNode = (node) => {
    return <a href="javascript:void(0);" onClick={() => handleShow(node.nodeId)}> <div className="member-view-box">
      <div className="card bg-primary-lt">
        <div className="m-2">
          {node.nodeId} {node.nodeId != node.leg ? `- ${node.leg}` : ''}
        </div>
      </div>
    </div>
    </a>
  };

  const handlePeriodChange = (name, value) => {
    setSelectedPeriods(x => ({ ...x, [name]: value }));
  }

  return <>
    <div className="card">
      <div className="card-header">
        <ul className="nav nav-tabs card-header-tabs" data-bs-toggle="tabs">
          {trees.map((t, index) => {
            return <li key={t} className={`nav-item ${index === 0 ? 'active' : ''}`}>
              <a href={`#tabs-home-${index + 1}`} className={`nav-link ${index === 0 ? 'active' : ''}`} data-bs-toggle="tab">{t}</a>
            </li>
          })}
        </ul>
      </div>
      <div className="card-body">
        <div className="tab-content">

          {Object.keys(nodesMap).map((tree, index) => (
            <div key={tree} className={`tab-pane ${index === 0 ? 'show active' : ''}`} id={`tabs-home-${index + 1}`}>
              <h2>{tree} Tree View</h2>
              <div className="body genealogy-body genealogy-scroll">
                <div className="genealogy-tree">
                  <ul>
                    <li>
                      {renderNode({ nodeId: Object.keys(nodesMap[tree])[0], leg: 'Root' })}
                      {renderNestedList(tree, Object.keys(nodesMap[tree])[0])}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>

    <Modal showModal={activeValues != null} size="lg" onHide={() => setActiveValues()}>
      <div className="modal-header">
        <h5 className="modal-title">Node {activeValues && activeValues[0]?.nodeId} Values</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="card">
        <div className="card-header">
          <ul className="nav nav-tabs card-header-tabs" data-bs-toggle="tabs" role="tablist">
            {periods.map((p, index) => {
              return <li key={p} className="nav-item" role="presentation">
                <a href={`#tabs-home-${p}`} className={`nav-link ${index == 0 ? 'active' : ''}`} data-bs-toggle="tab" aria-selected="true" role="tab">{p}</a>
              </li>
            })}
          </ul>
        </div>
        <div className="card-body">
          <div className="tab-content">
            {periods.map((p, index) => {
              var periodDate = (selectedPeriods && p in selectedPeriods) ? selectedPeriods[p] : '';
              return <div key={p} className={`tab-pane ${index == 0 ? 'active show' : ''}`} id={`tabs-home-${p}`} role="tabpanel">
                <div className="mb-3">
                  <SelectInput name={p} value={periodDate} onChange={handlePeriodChange}>
                    {activePeriods && activePeriods.filter((el) => el.periodName == p).map((period) => {
                      return <option key={period.periodDate} value={period.periodDate}><LocalDate dateString={period.periodDate} /></option>
                    })}
                  </SelectInput>
                </div>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Term</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {activeValues && activeValues.filter((el) => el.periodName == p && el.periodDate == periodDate).sort((a, b) => {
                      if (a.periodDate === b.periodDate) {
                        return a.valueKey > b.valueKey ? 1 : -1;
                      } else {
                        return a.periodDate > b.periodDate ? 1 : -1;
                      }
                    }).map((item) => {
                      return <tr key={item.valueKey}>
                        <td>{item.valueKey}</td>
                        <td>{item.value}</td>
                      </tr>
                    })}
                  </tbody>
                </table>

                <table className="table">
                  <thead>
                    <tr>
                      <th>Bonus Id</th>
                      <th>Volume</th>
                      <th>Percent</th>
                      <th>Amount</th>
                      <th>Commission Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {activeBonuses && activeBonuses.filter((el) => el.periodName == p && el.periodDate == periodDate).sort((a, b) => (a.valueKey > b.valueKey) ? 1 : -1).map((item) => {
                      return <tr key={item.bonusDetail}>
                        <td>{item.bonusId}</td>
                        <td>{item.volume}</td>
                        <td>{item.percent}</td>
                        <td>{item.amount}</td>
                        <td><LocalDate dateString={item.commissionDate} /></td>
                      </tr>
                    })}
                  </tbody>
                </table>
              </div>
            })}
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn" data-bs-dismiss="modal">Close</button>
      </div>
    </Modal>
  </>
};

export default ScenarioResults;

ScenarioResults.propTypes = {
  results: PropTypes.any.isRequired,
}
