import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from "../../../components/modal";
import Switch from "../../../components/switch";
import TextInput from '../../../components/textInput';
import NumericInput from '../../../components/numericInput';
import SelectInput from '../../../components/selectInput';
import QualificationInput from './qualificationInput';
import QualificationSelect from './qualificationSelect';

const RankSettings = ({ ranks, customerTypes, updateRanks, definitions, periods }) => {
  const [activeItem, setActiveItem] = useState();
  const [deleteId, setDeleteId] = useState();
  const [showDelModal, setShowDelModal] = useState();

  useEffect(() => {
    if (activeItem) {
      var index = ranks.findIndex((rank) => rank.uiId === activeItem.uiId);

      if (index !== -1) {
        ranks[index] = activeItem;
      } else {
        ranks.push(activeItem);
      }

      updateRanks(ranks);
    }
  }, [activeItem])

  /*  const handleSubmit = () => {
      
      setShow(false);
    }*/
  const firstRank = ranks[0] ?? {};

  const handleChange = (name, value) => {
    setActiveItem((item) => ({ ...item, [name]: value }));
  }

  const handleHide = () => setActiveItem();
  const handleShow = (uiId) => {
    var rank = ranks.find((rank) => rank.uiId === uiId) ?? { uiId: crypto.randomUUID(), periodId: periods[0]?.id, qualVolumeKey: ranks[0]?.qualVolumeKey, groupVolumeKey: ranks[0]?.groupVolumeKey, legVolumeKey: ranks[0]?.legVolumeKey, requirements: [] };
    setActiveItem(rank);
  }

  const handleHideDelModal = () => setShowDelModal(false);
  const handleShowDelModel = (rankId) => {
    setDeleteId(rankId);
    setShowDelModal(true);
  }

  const deleteRank = () => {
    let index = ranks.findIndex((el) => el.uiId == deleteId);
    if (index > -1) {
      ranks.splice(index, 1)
      updateRanks(ranks);
    }

    setActiveItem();
    setShowDelModal(false);
  }

  const handleRequirementChange = (uiId, name, value) => {
    setActiveItem(item => {
      var index = item.requirements.findIndex((el) => el.uiId == uiId);
      if (index !== -1) {
        var tempItem = item.requirements[index];
        item.requirements[index] = { ...tempItem, [name]: value };
      }

      return { ...item };
    });
  }

  const handleRequirementAdd = () => {
    setActiveItem(item => {
      item.requirements.push({
        qualVolume: 0,
        groupVolume: 0,
        legVolumeCap: 0,
        maintanance: false,
        uiId: crypto.randomUUID()
      })
      return { ...item };
    });
  }

  const handleRequirmentRemove = (uiId) => {
    setActiveItem(item => {
      var index = item.requirements.findIndex((el) => el.uiId == uiId);
      if (index !== -1) {
        item.requirements.splice(index, 1);
      }

      return { ...item };
    });
  }

  const handleQualificationChange = (reqUiId, qualUiId, name, value) => {
    setActiveItem(item => {
      var index = item.requirements.findIndex((el) => el.uiId == reqUiId);
      if (index !== -1) {
        var qualIndex = item.requirements[index].qualifications.findIndex((el) => el.uiId == qualUiId);
        var tempItem = item.requirements[index].qualifications[qualIndex];
        item.requirements[index].qualifications[qualIndex] = { ...tempItem, [name]: value };
      }

      return { ...item };
    });
  }

  const handleQualificationRemove = (reqUiId, qualUiId) => {
    setActiveItem(item => {
      var req = item.requirements.find((el) => el.uiId == reqUiId);
      var index = req.qualifications.findIndex((el) => el.uiId == qualUiId);

      if (index > -1) {
        req.qualifications.splice(index, 1);
      }

      return { ...item };
    });
  }

  const handleQualificationAdd = (uiId) => {
    setActiveItem(item => {
      var gen = item.requirements.find((el) => el.uiId == uiId);

      if (gen.qualifications == undefined) gen.qualifications = [];
      gen.qualifications.push({
        key: '_new_',
        value: 0,
        uiId: crypto.randomUUID()
      });

      return { ...item };
    });
  }

  if (activeItem) {
    return <>
      <div className="card-header">
        <h3 className="card-title">Rank Details</h3>
        <div className="card-actions btn-actions">
          <button className="btn btn-action" onClick={handleHide}>
            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
          </button>
        </div>
      </div>
      <div className="card-body">
        <div className="row mb-3">
          <div className="col-2">
            <label className="form-label">Index</label>
            <NumericInput name="id" value={activeItem?.id ?? ''} onChange={handleChange} />
          </div>
          <div className="col-6">
            <label className="form-label">Name</label>
            <TextInput name="name" value={activeItem?.name ?? ''} onChange={handleChange} />
          </div>
          <div className="col-2">
            <label className="form-label">Code</label>
            <TextInput name="code" value={activeItem?.code ?? ''} onChange={handleChange} />
          </div>
          <div className="col-2">
            <label className="form-label">Period</label>
            <SelectInput name="periodId" value={activeItem.periodId} onChange={handleChange}>
              {periods && periods.map((p) => {
                return <option key={p.id}>{p.id}</option>
              })}
            </SelectInput>
          </div>
        </div>
      </div>
      {activeItem.requirements && activeItem.requirements.map((r) => {
        return <><div key={r.qualVolume} className="card-header bg-dark-lt">
          <div className="card-actions btn-actions">
            <button className="btn-action" onClick={() => handleRequirmentRemove(r.uiId)}>
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash" width="40" height="40" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M4 7l16 0"></path><path d="M10 11l0 6"></path><path d="M14 11l0 6"></path><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path></svg>
            </button>
          </div>
        </div>
          <div className="card-body">
            <div className="row mb-3">
              <div className="col">
                <label className="form-label">Qualifying Volume</label>
                <NumericInput name="qualVolume" value={r.qualVolume} onChange={(name, value) => handleRequirementChange(r.uiId, name, value)} />
              </div>
              <div className="col">
                <label className="form-label">Group Volume</label>
                <NumericInput name="groupVolume" value={r.groupVolume} onChange={(name, value) => handleRequirementChange(r.uiId, name, value)} />
              </div>
              <div className="col">
                <label className="form-label">Max Volume each leg</label>
                <NumericInput name="legVolumeCap" value={r.legVolumeCap} onChange={(name, value) => handleRequirementChange(r.uiId, name, value)} />
              </div>
            </div>
            <Switch title="Include Personal Volume in Group Volume" name="includePersonal" value={r.includePersonal} onChange={(name, value) => handleRequirementChange(r.uiId, name, value)} />
            <Switch title="Maintanance" name="maintanance" value={r.maintanance} onChange={(name, value) => handleRequirementChange(r.uiId, name, value)} />
          </div>
          <div className="table-responsive">
            <table className="table card-table table-vcenter text-nowrap">
            <thead style={{display: 'none'}}>
                <tr>
                  <th className="">Term</th>
                  <th className="">Required</th>
                  <th className="w-1"></th>
                </tr>
              </thead>
              <tbody>
                {r.qualifications && r.qualifications.map((qual) => {
                  return <tr key={qual.uiId}>
                    <td>
                      <QualificationSelect definitions={definitions} periodId={activeItem.periodId} value={qual.key} name="key" onChange={(name, value) => handleQualificationChange(r.uiId, qual.uiId, name, value)} />
                    </td>
                    <td>
                      <QualificationInput ranks={ranks} customerTypes={customerTypes} qualKey={qual.key} value={qual.value ?? qual.volume ?? ''} name={'value'} onChange={(name, value) => handleQualificationChange(r.uiId, qual.uiId, name, value)} />
                    </td>
                    <td className="w-1">
                      <div className="btn-list flex-nowrap">
                        <button className="btn btn-action" onClick={() => handleQualificationRemove(r.uiId, qual.uiId)}>
                          <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash" width="40" height="40" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M4 7l16 0"></path><path d="M10 11l0 6"></path><path d="M14 11l0 6"></path><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path></svg>
                        </button>
                      </div>
                    </td>
                  </tr>
                })}
              </tbody>
            </table>
          </div>
          <div className="card-footer bg-transparent mt-auto">
            <div className="d-flex">
              <button className="btn ms-auto" onClick={() => handleQualificationAdd(r.uiId)}>
                New Requirement
              </button>
            </div>
          </div>
        </>
      })}
      <div className="card-footer mt-auto">
        <div className="row">
          <div className="col">
            <button className="btn btn-outline-primary mt-3" onClick={() => handleRequirementAdd()}>
              New Qualification
            </button>
          </div>
          <div className="col">
            <label className="form-label">Qualifying Volume Term</label>
            <QualificationSelect definitions={definitions} periodId={activeItem.periodId} hideRank={true} name="qualVolumeKey" value={activeItem.qualVolumeKey} onChange={handleChange} />
          </div>
          <div className="col">
            <label className="form-label">Group Volume Term</label>
            <QualificationSelect definitions={definitions.filter((p) => p.componentId == 'GVTask')} periodId={activeItem.periodId} hideRank={true} name="groupVolumeKey" value={activeItem.groupVolumeKey} onChange={handleChange} />
          </div>
        </div>
      </div>
    </>
  }


  return <>
    <div className="card-header">
      <h2 className="mb-0">Ranks</h2>
    </div>
    <div className="table-responsive">
      <table className="table card-table table-vcenter text-nowrap datatable">
        <thead>
          <tr>
            <th>Index</th>
            <th>Code</th>
            <th>Title</th>
            <th>{firstRank.qualVolumeKey}</th>
            <th>{firstRank.groupVolumeKey}</th>
            <th>Leg Cap</th>
            <th>Leg Cap %</th>
            <th>Maintinance</th>
            <th>Period</th>
            <th className="w-1"></th>
          </tr>
        </thead>
        <tbody>
          {ranks && ranks.sort((a, b) => (a.id > b.id) ? 1 : -1).map((rank, index) => {
            let isIdDisplayed = false;
            if (rank.requirements && rank.requirements.length > 0) {
              return rank.requirements.map((hv) => {
                const displayId = !isIdDisplayed && (index === 0 || ranks[index - 1].id !== rank.id);

                if (displayId) {
                  isIdDisplayed = true;
                }

                return (
                  <tr key={`${rank.uiId}_${hv.uiId}`}>
                    {displayId ? <td>{rank.id}</td> : <td></td>}
                    <td>{rank.code}</td>
                    <td>{rank.name}</td>
                    <td>{hv.qualVolume}</td>
                    <td>{hv.groupVolume}</td>
                    <td>{hv.legVolumeCap}</td>
                    <td>
                      {hv.legVolumeCap > 0 && Math.round((hv.legVolumeCap / hv.groupVolume) * 100) + `%`}
                    </td>
                    <td>{hv.maintanance ? 'Yes' : 'No'}</td>
                    <td>{rank.periodId}</td>
                    {displayId ? <td>
                      <div className="btn-list flex-nowrap">
                        <button className="btn" onClick={() => { handleShow(rank.uiId) }}>
                          Edit
                        </button>
                        <button className="btn btn-icon" onClick={() => handleShowDelModel(rank.uiId)}>
                          <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><line x1="4" y1="7" x2="20" y2="7"></line><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path></svg>
                        </button>
                      </div>
                    </td> : <td></td>}
                  </tr>
                );
              })
            }
            if (!rank.requirements || rank.requirements.length == 0) {
              return (
                <tr key={rank.uiId}>
                  <td>{rank.id}</td>
                  <td>{rank.code}</td>
                  <td>{rank.name}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <div className="btn-list flex-nowrap">
                      <button className="btn" onClick={() => { handleShow(rank.uiId) }}>
                        Edit
                      </button>
                      <button className="btn btn-icon" onClick={() => handleShowDelModel(rank.uiId)}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><line x1="4" y1="7" x2="20" y2="7"></line><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path></svg>
                      </button>
                    </div>
                  </td>
                </tr>
              );
            }
          })}

        </tbody>
      </table>
    </div>
    <div className="card-footer bg-transparent mt-auto">
      <div className="btn-list justify-content-end">
        <button className="btn btn-outline-primary" onClick={() => { handleShow() }} >
          New Rank
        </button>
      </div>
    </div>

    <Modal showModal={showDelModal} size="sm" onHide={handleHideDelModal}>
      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      <div className="modal-body py-4">
        <div className="modal-title">Are you sure?</div>
        <div className="text-muted">Do you really want to remove this rank? It cannot be undone.</div>
      </div>

      <div className="modal-footer">
        <div className="w-100">
          <div className="row">
            <div className="col">
              <button type="button" className="btn w-100" data-bs-dismiss="modal">Cancel</button>
            </div>
            <div className="col">
              <button type="button" className="btn btn-danger w-100" onClick={deleteRank} >Delete Rank</button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </>
};

export default RankSettings;

RankSettings.propTypes = {
  ranks: PropTypes.any.isRequired,
  customerTypes: PropTypes.any.isRequired,
  updateRanks: PropTypes.func.isRequired,
  definitions: PropTypes.any.isRequired,
  periods: PropTypes.any.isRequired
}
