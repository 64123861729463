import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom"
import { useFetch } from '../../hooks/useFetch'
import { SendRequest } from '../../hooks/usePost'
import DataLoading from '../../components/dataLoading'
import VolumeSettings from './editComponents/volumeSettings';
import TreeSettings from "./editComponents/treeSettings";
import RankSettings from './editComponents/rankSettings';
import BonuseSettings from './editComponents/bonusSettings';
import GeneralSettings from './editComponents/generalSettings';
import Scenarios from './editComponents/scenarios';
import TextInput from '../../components/textInput';

const TemplateEdit = () => {
  const params = useParams();
  const [tab, setTab] = useState('general');
  const [planData, setPlanData] = useState();
  const { loading, error, data } = useFetch("/api/v1/Templates/" + params.id);

  useEffect(() => {
    if (data) {
      data.ranks = data.ranks?.map((r) => ({
        ...r,
        uiId: r.uiId ?? crypto.randomUUID(),
        periodId: r.periodId ?? data?.periods[0]?.incrementType,
        requirements: r.requirements.map((r) => ({
          ...r,
          uiId: r.uiId ?? crypto.randomUUID(),
          qualifications: r.qualifications?.map((q) => ({ ...q, uiId: q.uiId ?? crypto.randomUUID() }))
        })),
      }));
      data.definitions = data.definitions?.map((d) => ({
        ...d,
        periodId: d.periodId ?? data?.periods[0]?.incrementType,
        uiId: d.uiId ?? crypto.randomUUID()
      }));
      data.trees = data.trees?.map((t) => ({
        ...t,
        default: t.name == data.defaultTree,
        uiId: t.uiId ?? crypto.randomUUID()
      }));
      data.periods = data.periods?.map((p) => ({
        ...p,
        uiId: p.uiId ?? crypto.randomUUID()
      }));
      data.bonusDefinitions = data.bonusDefinitions?.map((b) => ({
        ...b,
        periodId: (b.periodId ?? '') == '' ? data?.periods[0]?.incrementType : b.periodId,
        uiId: b.uiId ?? crypto.randomUUID()
      }));
      data.tests = data.tests?.map((t) => ({
        ...t,
        uiId: t.uiId ?? crypto.randomUUID(),
        steps: assignUiIds(t.steps),
        childErrors: countChildErrors(t.steps)
      }));

      data.customerTypes = data.sourceGroups.find((element) => element.id.toLowerCase() == 'custtype')?.acceptedValues.map((c) => ({ ...c, uiId: c.uiId ?? crypto.randomUUID() }));

    }
    setPlanData(data);
  }, [data]);

  const assignUiIds = (steps) => {
    return steps?.map((s) => ({
      ...s,
      uiId: s.uiId ?? crypto.randomUUID(),
      steps: assignUiIds(s.steps),
      childErrors: countChildErrors(s.steps)
    })) ?? [];
  };

  const countChildErrors = (steps) => {
    let errorCount = 0;

    if (steps) {
      for (const step of steps) {
        if (step.error) {
          errorCount += 1;
        }

        errorCount += countChildErrors(step.steps);
      }
    }

    return errorCount;
  };

  if (loading || !planData) return <DataLoading />;
  if (error) return `Error! ${error}`;

  const handleChange = (name, value) => {
    setPlanData(d => ({ ...d, [name]: value }));
  }

  const handleUpdateCustTypes = (customerTypes) => {
    setPlanData(values => {
      var index = values.sourceGroups.findIndex((s) => s.id.toLowerCase() == 'custtype');
      if (index > -1) {
        values.sourceGroups[index].acceptedValues = customerTypes;
      }

      return ({ ...values, custtype: customerTypes });
    });
  }

  const handleUpdatePeriods = (periods) => {
    setPlanData(values => ({ ...values, periods: periods }));
  }

  const handleUpdateRanks = (rankData) => {
    setPlanData(values => ({ ...values, ranks: rankData }));
  }

  const handleUpdateBonuses = (bonusDefinitions) => {
    setPlanData(values => ({ ...values, bonusDefinitions: bonusDefinitions }));
  }

  const handleUpdateTerms = (terms) => {
    setPlanData(values => ({ ...values, definitions: terms }));
  }

  const handleUpdateTrees = (trees) => {
    const dt = trees.find((t) => t.default);
    setPlanData(values => ({ ...values, trees: trees, defaultTree: dt?.name }));
  }

  const handleUpdateTests = (tests) => {
    setPlanData(values => ({ ...values, tests: tests }));
  }

  const handleSave = () => {
    SendRequest("PUT", "/api/v1/Templates/" + params.id, planData, () => {
      alert('Save Success')
    }, (error) => {
      alert("Error: " + error);
    })
  }

  const latestBeginDate = planData.periods.reduce((latest, period) => {
    return period.beginDate > latest ? period.beginDate : latest;
  }, planData.periods[0].beginDate);

  return <>
    <div className="page-header d-print-none">
      <div className="container-xl">
        <div className="row align-items-center">
          <div className="col">
            <TextInput className="form-control-plaintext form-control-lg w-100 page-title ps-3 border" name="name" value={planData.name} onChange={handleChange} />
          </div>

          <div className="col-auto ms-auto d-print-none">
            <div className="btn-list">
              <button className="btn btn-primary d-none d-sm-inline-block" onClick={handleSave}>
                Save
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div className="page-body">

      <div className="container-xl">
        <div className="card">
          <div className="row g-0">
            <div className="col-2 d-none d-md-block border-end">
              <div className="card-body">
                <h4 className="subheader">Compensation Plan Settings</h4>
                <div className="list-group list-group-transparent">
                  <button className={`list-group-item list-group-item-action d-flex align-items-center ${tab == 'general' ? 'active' : ''}`} onClick={() => setTab('general')} >General</button>
                  <button className={`list-group-item list-group-item-action d-flex align-items-center ${tab == 'trees' ? 'active' : ''}`} onClick={() => setTab('trees')}>Trees</button>
                  <button className={`list-group-item list-group-item-action d-flex align-items-center ${tab == 'volumes' ? 'active' : ''}`} onClick={() => setTab('volumes')}>Glossary of Terms</button>
                  <button className={`list-group-item list-group-item-action d-flex align-items-center ${tab == 'ranks' ? 'active' : ''}`} onClick={() => setTab('ranks')}>Ranks</button>
                  <button className={`list-group-item list-group-item-action d-flex align-items-center ${tab == 'bonuses' ? 'active' : ''}`} onClick={() => setTab('bonuses')}>Commissions &amp; Bonuses</button>
                </div>
                <h4 className="subheader mt-4">Advanced</h4>
                <div className="list-group list-group-transparent">
                  <button className={`list-group-item list-group-item-action d-flex align-items-center ${tab == 'scenarios' ? 'active' : ''}`} onClick={() => setTab('scenarios')}>Scenarios</button>
                  <button className={`list-group-item list-group-item-action d-flex align-items-center ${tab == 'components' ? 'active' : ''}`} onClick={() => setTab('components')}>Components</button>
                </div>
              </div>
            </div>
            <div className="col d-flex flex-column">
              {tab == 'general' && <GeneralSettings description={planData.description} definitions={planData.definitions} updateDescription={handleChange} periods={planData.periods} updatePeriods={handleUpdatePeriods} customerTypes={planData.customerTypes} updateCustomerTypes={handleUpdateCustTypes} />}
              {tab == 'trees' && <TreeSettings trees={planData.trees} definitions={planData.definitions} defaultTree={planData.defaultTree} updateTrees={handleUpdateTrees} />}
              {tab == 'volumes' && <VolumeSettings terms={planData.definitions} updateTerms={handleUpdateTerms} periods={planData.periods} />}
              {tab == 'ranks' && <RankSettings ranks={planData.ranks} customerTypes={planData.customerTypes} updateRanks={handleUpdateRanks} definitions={planData.definitions} periods={planData.periods} />}
              {tab == 'bonuses' && <BonuseSettings bonuses={planData.bonusDefinitions} updateBonuses={handleUpdateBonuses} definitions={planData.definitions} customerTypes={planData.customerTypes} ranks={planData.ranks} periods={planData.periods} />}
              {tab == 'scenarios' && <Scenarios tests={planData.tests} updateTests={handleUpdateTests} customerTypes={planData.customerTypes} highPeriodDate={latestBeginDate} />}
            </div>
          </div>
        </div>

        {/* {planData.tests && planData.tests.map((d) => {
          return <div key={d.id} className="card">
            <div className="card-body">
              {JSON.stringify(d.resultData)}
            </div>
          </div>
        })} */}

      </div>
    </div>
  </>
};

export default TemplateEdit;