import React from 'react';
import PropTypes from 'prop-types';

const TextArrayInput = ({ className, name, value, onChange, disabled, placeholder }) => {

  const handleChange = (event) => {
    var name = event.target.name;
    var value = event.target.value;

    onChange(name, value.split(',').map(item => item.trim()));
  };

  const inputValue = value?.join(',') ?? '';

  return <input className={className ?? 'form-control'} placeholder={placeholder ?? ''} name={name} value={inputValue} disabled={disabled} onChange={handleChange} autoComplete='off' />
}

export default TextArrayInput;

TextArrayInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
}
