import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SelectInput from '../../../components/selectInput';

const QualificationInput = ({ className, name, value, onChange, disabled, qualKey, ranks, customerTypes }) => {

  useEffect(() => {
    if (qualKey?.toLowerCase() === "rank") {
      var index = ranks.findIndex((r) => r.id == value);
      if (index == -1) {
        onChange(name, ranks[0].id);
      }

    }
  }, [value, qualKey])

  const handleChange = (event) => {
    var name = event.target.name;
    var value = Number(event.target.value);
    onChange(name, value);
  };

  const handleSelectChange = (name, value) => {
    onChange(name, value);
  }

  if (qualKey?.toLowerCase() === "rank") {
    return <SelectInput name={name} value={value} disabled={disabled} onChange={handleSelectChange} >
      {ranks && ranks.map((r) => {
        return <option key={r.id} value={r.id}>{r.name}</option>
      })}
    </SelectInput>
  }

  if (qualKey?.toLowerCase() === "custtype") {
    return <SelectInput name={name} value={value} disabled={disabled} onChange={handleSelectChange} >
      {customerTypes && customerTypes.map((cType) => {
        return <option key={cType.uiId ?? cType.value} value={cType.value}>{cType.description}</option>
      })}
    </SelectInput>
  }

  return <input type="number" className={className ?? "form-control"} name={name} value={value > 0 ? value : ''} disabled={disabled} autoComplete='off' onChange={handleChange}></input>;
}

export default QualificationInput;


QualificationInput.propTypes = {
  className: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
  qualKey: PropTypes.bool.isRequired,
  ranks: PropTypes.bool.isRequired,
  customerTypes: PropTypes.any.isRequired,
}
