import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from "../../../components/modal";
import TextInput from '../../../components/textInput';
import SelectInput from '../../../components/selectInput';
import Switch from '../../../components/switch';
import TextArrayInput from '../../../components/textArrayInput';
import QualificationSelect from './qualificationSelect';

const TreeSettings = ({ trees, definitions, updateTrees }) => {
  const [activeItem, setActiveItem] = useState();
  const [deleteId, setDeleteId] = useState();
  const [showDelModal, setShowDelModal] = useState();

  useEffect(() => {
    if (activeItem) {
      var index = trees.findIndex((tree) => tree.uiId === activeItem.uiId);

      if (index !== -1) {
        trees[index] = activeItem;
      } else {
        trees.push(activeItem);
      }

      updateTrees(trees);
    }
  }, [activeItem])

  const handleChange = (name, value) => {
    setActiveItem((item) => ({ ...item, [name]: value }));
  }

  const handleHide = () => setActiveItem();
  const handleShow = (uiId) => {
    var rank = trees.find((tree) => tree.uiId === uiId) ?? { uiId: crypto.randomUUID(), buildRule: "Flat" };
    setActiveItem(rank);
  }

  const handleHideDelModal = () => setShowDelModal(false);
  const handleShowDelModel = (treeId) => {
    setDeleteId(treeId);
    setShowDelModal(true);
  }

  const deleteDefinition = () => {
    let index = trees.findIndex((el) => el.uiId == deleteId);
    if (index > -1) {
      trees.splice(index, 1)
      updateTrees(trees);
    }

    setActiveItem();
    setShowDelModal(false);
  }

  if (activeItem) {
    const showLegKey = activeItem.buildRule == 'Highest' || activeItem.buildRule == 'Lowest';
    return <>
      <div className="card-header">
        <h3 className="card-title">Term Details</h3>
        <div className="card-actions btn-actions">
          <button className="btn btn-action" onClick={handleHide}>
            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
          </button>
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-8 mb-3">
            <label className="form-label">Name</label>
            <TextInput name="name" value={activeItem.name} onChange={handleChange} />
          </div>
          <div className="col-4 mb-3">
            <label className="form-label">Placement Term</label>
            <QualificationSelect definitions={definitions} hideRank={true} emptyText="None" name="requiredValue" value={activeItem.requiredValue} onChange={handleChange} />
          </div>
          <div className="col-8 mb-3">
            <label className="form-label">Leg Names</label>
            <TextArrayInput placeholder="Seperate each leg with an comma." name="legNames" value={activeItem.legNames} onChange={handleChange} />
            <small className="form-hint">
            Please enter the leg names from left to right, irrespective of their labels.
            </small>
          </div>
          <div className="col-4 mb-3">
            <label className="form-label">Build Pattern</label>
            <TextInput name="buildPattern" value={activeItem.buildPattern} onChange={handleChange} />
          </div>
          <div className={`col-${showLegKey ? '8' : '12'} mb-3`}>
            <label className="form-label">Build Rule</label>
            <SelectInput name="buildRule" value={activeItem.buildRule} onChange={handleChange} >
              <option value="Flat" >Flat - New enrollments will be placed direcly under the enroller</option>
              <option value="Inside" >Inside - New enrollments will be placed on the Inside leg of the enroller</option>
              <option value="Outside" >Outside - New enrollments will be placed on the Outside leg of the enroller</option>
              <option value="Highest" >Highest - New enrollments will be placed on the Highest leg of the enroller, based on LegVolumeKey</option>
              <option value="Lowest" >Lowest - New enrollments will be placed on the Lowest leg of the enroller, based on LegVolumeKey</option>
            </SelectInput>
          </div>
          {showLegKey && <div className="col-4 mb-3">
            <label className="form-label">Leg Volume Term</label>
            <QualificationSelect definitions={definitions} hideRank={true} name="legVolumeKey" value={activeItem.legVolumeKey} onChange={handleChange} />
          </div>}
          <div className="mb-3">
            <Switch title="Default Tree" name="default" value={activeItem.default} onChange={handleChange} />
          </div>
        </div>
      </div>
    </>
  }

  return <>
    <div className="card-header">
      <h2 className="mb-0">Trees</h2>
    </div>
    <div className="table-responsive">
      <table className="table card-table table-vcenter text-nowrap datatable">
        <thead>
          <tr>
            <th>Name</th>
            <th>Legs</th>
            <th>Build Rule</th>
            <th>Build Pattern</th>
            <th>Placement Term</th>
            <th className="w-1"></th>
          </tr>
        </thead>
        <tbody>
          {trees && trees.map((tree) => {
            return <tr key={tree.name}>
              <td>{tree.name}</td>
              <td>
                <div className="list-inline list-inline-dots mb-0 d-sm-block d-none">
                  {tree.legNames ? tree.legNames.map((l) => {
                    return <div className="list-inline-item" key={l}>{l?.trim()}</div>
                  }) : '-'}
                </div>
              </td>
              <td>{tree.buildRule ?? '-'}</td>
              <td>{tree.buildPattern ?? '-'}</td>
              <td>{(tree.requiredValue ?? '') == '' ? '-' : tree.requiredValue}</td>
              <td>
                <div className="btn-list flex-nowrap">
                  <button className="btn" onClick={() => { handleShow(tree.uiId) }}>
                    Edit
                  </button>
                  <button className="btn btn-icon" onClick={() => handleShowDelModel(tree.uiId)}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><line x1="4" y1="7" x2="20" y2="7"></line><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path></svg>
                  </button>
                </div>
              </td>
            </tr>
          })}
        </tbody>
      </table>
    </div>
    <div className="card-footer bg-transparent mt-auto">
      <div className="btn-list justify-content-end">
        <button className="btn btn-outline-primary" onClick={() => { handleShow() }} >
          New Tree
        </button>
      </div>
    </div>

    <Modal showModal={showDelModal} size="sm" onHide={handleHideDelModal}>
      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      <div className="modal-body py-4">
        <div className="modal-title">Are you sure?</div>
        <div className="text-muted">Do you really want to remove this tree? It cannot be undone.</div>
      </div>

      <div className="modal-footer">
        <div className="w-100">
          <div className="row">
            <div className="col">
              <button type="button" className="btn w-100" data-bs-dismiss="modal">Cancel</button>
            </div>
            <div className="col">
              <button type="button" className="btn btn-danger w-100" onClick={deleteDefinition} >Delete Tree</button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </>
};

export default TreeSettings;

TreeSettings.propTypes = {
  trees: PropTypes.any.isRequired,
  definitions: PropTypes.any.isRequired,
  updateTrees: PropTypes.func.isRequired,
}