import React from 'react';
import { Outlet } from "react-router-dom";
import PropTypes from 'prop-types';
import { GetUser } from "../features/authentication/hooks/useToken"
import CorporateMenu from './corporateMenu';
import Footer from "./footer";

const Layout = ({ clearToken }) => {
  var user = GetUser();

  const handleLogout = () => {
    clearToken();
    window.location = "/";
  };

  return <>
    <div className="page-wrapper">
      <header className="navbar navbar-expand-md navbar-dark d-print-none">
        <div className="container-xl">
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-menu" aria-controls="navbar-menu" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <h1 className="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
            <a href='/'>
              <img src="/images/logo1.png" width="110" height="32" alt="Pillars" className="navbar-brand-image" />
            </a>
          </h1>
          <div className="navbar-nav flex-row order-md-last">
            <div className="navbar-nav flex-row order-md-last">
              <div className="nav-item dropdown">
                <a href="#" className="nav-link d-flex lh-1 text-reset p-0" data-bs-toggle="dropdown" aria-label="Open user menu">
                  <span className="avatar avatar-sm">{user.firstName?.charAt().toUpperCase()}{user.lastName?.charAt().toUpperCase()}</span>
                  <div className="d-none d-xl-block ps-2">
                    <div>{user.firstName} {user.lastName}</div>
                    <div className="mt-1 small text-muted">Service Rep</div>
                  </div>
                </a>
                <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                  <a className="dropdown-item" href="/profile" title="Manage">Profile</a>
                  <button className="dropdown-item" onClick={handleLogout}>Logout</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="navbar-expand-md">
        <div className="collapse navbar-collapse" id="navbar-menu">
          <div className="navbar navbar-light">
            <div className="container-xl">
              <ul className="navbar-nav">
                <CorporateMenu />
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Outlet />
      <Footer />
    </div>
  </>
};

Layout.propTypes = {
  clearToken: PropTypes.func.isRequired
}

export default Layout;