import React, { useState } from "react";
import { SendRequest } from '../../hooks/usePost';

const ForgotPassword = () => {
  const [emailAddress, setEmailAddress] = useState();

  const handleChangePassword = (e) => {
    e.preventDefault();

    let os = "windows";
    let browser = "chrome";

    SendRequest("POST", `/Authentication/ResetPassword?emailAddress=${emailAddress}&operatingSystem=${os}&browserName=${browser}`, {}, () => {
      var successAlert = document.getElementById('passwordSuccess');
      successAlert.classList.remove('d-none');
    }, (error, code) => {
      alert(`${code}: ${error}`);
    });

  }

  return <>
    <div className="page page-center">
      <div className="container container-tight py-4">
        <form className="card card-md" action="./" method="get" autoComplete="off" noValidate="">
          <div className="card-body">
            <div className="text-center mb-4 d-print-none text-white">
              <a href="." className="navbar-brand navbar-brand-autodark">
                <img src="/images/logo-dark.png" height="46" alt="Pillars" />
              </a>
            </div>
            <h2 className="h2 text-center mb-4">Forgot password</h2>
            <p className="text-muted mb-4">Enter your email address and instructions on how to reset your password will be emailed to you.</p>

            <div id="passwordSuccess" className="alert alert-success d-none" role="alert">
              An email with instructions has been sent.  If it does not show up, please check your email spam folder.
            </div>

            <div className="mb-3">
              <label className="form-label">Email address</label>
              <input type="email" className="form-control" placeholder="Enter email" value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} />
            </div>
            <div className="form-footer">
              <button href="#" className="btn btn-primary w-100" onClick={handleChangePassword}>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><rect x="3" y="5" width="18" height="14" rx="2"></rect><polyline points="3 7 12 13 21 7"></polyline></svg>
                Send me new password
              </button>
            </div>
          </div>
        </form>
        <div className="text-center text-muted mt-3">
          Return to <a href="/">Sign In</a>
        </div>
      </div>
    </div>
  </>
}

export default ForgotPassword;