import { useState, useEffect } from 'react';
import { GetToken } from '../features/authentication/hooks/useToken';
import BaseUrl from './baseUrl';

function useFetch(url, params) {
  const [variables, setVariables] = useState(params);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    get_private();
  }, [url, variables])

  function refetch(prms) {
    setVariables(cur => {
      const combined = { ...cur, ...prms };
      const filteredCombined = Object.fromEntries(
        Object.entries(combined).filter(([, value]) => value !== null)
      );
      return filteredCombined;
    });
  }
  

  function get_private() {
    const objString = variables ? '?' + new URLSearchParams(variables).toString() : '';
    Get(url + objString, (r) => {
      setLoading(false);
      setError(null);
      setData(r);
    },
      (error) => {
        setLoading(false);
        setError(error);
      });
  }

  return { data, loading, error, variables, refetch: refetch }
}

function Get(url, onSuccess, onError) {
  let xhr = new XMLHttpRequest();
  let fullUrl = BaseUrl + url;
  if (url.startsWith("https")) fullUrl = url;

  // open a connection
  xhr.open("GET", fullUrl, true);

  // Set the request header i.e. which type of content you are sending
  xhr.setRequestHeader("Content-Type", "application/json");
  xhr.setRequestHeader("Authorization", GetToken()?.token);

  // Create a state change callback
  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4) {
      if (xhr.status >= 200 && xhr.status <= 205) {
        onSuccess(JSON.parse(xhr.response));
      }
      else {
        onError(xhr.response, xhr.status);
      }
    }
  };

  // Sending data with the request
  xhr.send();
}

export { useFetch, Get };