import React from 'react';
import PropTypes from 'prop-types';



const TextArea = ({ className, name, value, onChange, disabled, placeholder }) => {

    const handleChange = (event) => {
        
        var name = event.target.name;
        var value = event.target.value;
        
        onChange(name, value);
    };

    return <textarea className={className ?? "form-control"} placeholder={placeholder ?? ''} name={name} value={value} disabled={disabled} onChange={handleChange}></textarea>
}

export default TextArea;

TextArea.propTypes = {
    className: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
    disabled: PropTypes.bool.isRequired,
    children: PropTypes.any.isRequired,
    placeholder: PropTypes.string
}
