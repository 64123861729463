import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextArea from "../../../components/textArea"
import LocalDate from "../../../util/LocalDate"
import Modal from '../../../components/modal';
import SelectInput from '../../../components/selectInput';
import NumericInput from '../../../components/numericInput';
import DateTimeInput from '../../../components/dateTimeInput';
import TextInput from '../../../components/textInput';
import QualificationSelect from './qualificationSelect';

const GeneralSettings = ({ customerTypes, definitions, updateCustomerTypes, periods, updatePeriods, description, updateDescription }) => {
  const [showPeriod, setShowPeriod] = useState();
  const [activePeriod, setActivePeriod] = useState();
  const [showCustomerType, setShowCustomerType] = useState();
  const [activeCustomerType, setActiveCustomerType] = useState();
  const [deleteItem, setDeleteItem] = useState();
  const [showDelModal, setShowDelModal] = useState();

  const handleHideDelModal = () => setShowDelModal(false);
  const handleShowDelModel = (uiId, isPeriod) => {
    setDeleteItem({ uiId, isPeriod });
    setShowDelModal(true);
  }

  const handleDeleteItem = () => {
    if (deleteItem.isPeriod) {
      handleDeletePeriod();
    } else {
      handleDeleteCustomerType();
    }
  }

  const handleDeletePeriod = () => {
    let index = periods.findIndex((el) => el.uiId == deleteItem.uiId);
    if (index > -1) {
      periods.splice(index, 1)
      updatePeriods(periods);
    }
    setShowDelModal(false);
  }

  const handleDeleteCustomerType = () => {
    let index = customerTypes.findIndex((el) => el.uiId == deleteItem.uiId);
    if (index > -1) {
      customerTypes.splice(index, 1)
      updateCustomerTypes(customerTypes);
    }
    setShowDelModal(false);
  }

  const handleChange = (name, value) => {
    updateDescription(name, value)
  }

  const handleHidePeriod = () => setShowPeriod(false);
  const handleShowPeriod = (uiId) => {
    var period = periods?.find((rank) => rank.uiId === uiId) ?? { uiId: crypto.randomUUID(), incrementType: "Month", incrementAmount: 1 };
    period.timeZone = period?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
    setActivePeriod(period);
    setShowPeriod(true);
  }

  const handleHideCustomerType = () => setShowCustomerType(false);
  const handleShowCustomerType = (uiId) => {
    var cType = customerTypes?.find((c) => c.uiId === uiId) ?? { uiId: crypto.randomUUID() };
    setActiveCustomerType(cType);
    setShowCustomerType(true);
  }

  const handleCustomerTypeChange = (name, value) => {
    setActiveCustomerType((c) => ({ ...c, [name]: value }));
  }

  const handlePeriodChange = (name, value) => {
    setActivePeriod((p) => ({ ...p, [name]: value }));
  }

  const handleSavePeriod = () => {
    if (activePeriod) {
      var index = periods?.findIndex((p) => p.uiId === activePeriod.uiId) ?? -1;

      if (index !== -1) {
        periods[index] = activePeriod;
      } else {
        if (!periods) periods = [];
        periods.push(activePeriod);
      }

      updatePeriods(periods);
    }

    setShowPeriod(false);
  }

  const handleSaveCustomerType = () => {
    if (activeCustomerType) {
      var index = customerTypes?.findIndex((p) => p.uiId === activeCustomerType.uiId) ?? -1;

      if (index !== -1) {
        customerTypes[index] = activeCustomerType;
      } else {
        if (!customerTypes) periods = [];
        customerTypes.push(activeCustomerType);
      }

      updateCustomerTypes(customerTypes);
    }

    setShowCustomerType(false);
  }

  return <>
    <div className="card-header">
      <h2 className="mb-0">General Settings</h2>
    </div>
    <div className="card-body">
      <div className="mb-3">
        <label className="form-label">Description</label>
        <TextArea name="description" value={description} onChange={handleChange} />
      </div>
    </div>

    <div className='row'>
      <div className="col-md-6">

        <div className="card-header">
          <h2 className="mb-0">Periods</h2>
        </div>
        <div className="table-responsive">
          <table className="table card-table table-vcenter text-nowrap datatable">
            <thead>
              <tr>
                <th>Index</th>
                <th>Name</th>
                <th>Begin Date</th>
                <th className="w-1"></th>
              </tr>
            </thead>
            <tbody>
              {periods && periods.sort((a, b) => a.index - b.index).map((p) => {
                return <tr key={p.uiId}>
                  <td>{p.index}</td>
                  <td>{p.id}</td>
                  <td><LocalDate dateString={p.beginDate} /></td>
                  <td>
                    <div className="btn-list flex-nowrap">
                      <button className="btn" onClick={() => handleShowPeriod(p.uiId)}>
                        Edit
                      </button>
                      <button className="btn btn-icon" onClick={() => handleShowDelModel(p.uiId, true)}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><line x1="4" y1="7" x2="20" y2="7"></line><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path></svg>
                      </button>
                    </div>
                  </td>
                </tr>
              })}
            </tbody>
          </table>
        </div>
        <div className="card-footer bg-transparent mt-auto">
          <div className="btn-list justify-content-end">
            <button className="btn btn-outline-primary" onClick={() => handleShowPeriod()}>
              New Period
            </button>
          </div>
        </div>
      </div>

      <div className="col-md-6">
        <div className="card-header">
          <h2 className="mb-0">Customer Types</h2>
        </div>
        <div className="table-responsive">
          <table className="table card-table table-vcenter text-nowrap datatable">
            <thead>
              <tr>
                <th>Customer Type</th>
                <th>Description</th>
                <th className="w-1"></th>
              </tr>
            </thead>
            <tbody>
              {customerTypes && customerTypes.map((cType) => {
                return <tr key={cType.uiId}>
                  <td>{cType.value}</td>
                  <td>{cType.description}</td>
                  <td>
                    <div className="btn-list flex-nowrap">
                      <button className="btn" onClick={() => handleShowCustomerType(cType.uiId)}>
                        Edit
                      </button>
                      <button className="btn btn-icon" onClick={() => handleShowDelModel(cType.uiId, false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><line x1="4" y1="7" x2="20" y2="7"></line><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path></svg>
                      </button>
                    </div>
                  </td>
                </tr>
              })}
            </tbody>
          </table>
        </div>
        <div className="card-footer bg-transparent mt-auto">
          <div className="btn-list justify-content-end">
            <button className="btn btn-outline-primary" onClick={() => handleShowCustomerType()}>
              New Customer Type
            </button>
          </div>
        </div>
      </div>
    </div>

    <Modal showModal={showPeriod} size="lg" onHide={handleHidePeriod}>
      <div className="modal-header">
        <h5 className="modal-title">Period Settings</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <div className="row">
          <div className="col-2 mb-3">
            <label className="form-label">Index</label>
            <NumericInput name="index" value={activePeriod?.index ?? ''} onChange={handlePeriodChange} />
          </div>
          <div className="col-6 mb-3">
            <label className="form-label">Period Name</label>
            <TextInput name="id" value={activePeriod?.id ?? ''} onChange={handlePeriodChange} />
          </div>
          <div className="col-4 mb-3">
            <label className="form-label">Rank Synchronization</label>
            <SelectInput name="rankSync" value={activePeriod?.rankSync ?? ''} onChange={handlePeriodChange} >
              <option value="DoNotSync">None</option>
              <option value="Current">Current Rank</option>
              <option value="Highest">Highest Rank</option>
              <option value="Lowest">Lowest Rank</option>
            </SelectInput>
          </div>
          <div className="col-3 mb-3">
            <label className="form-label">Period Type</label>
            <SelectInput name="incrementType" value={activePeriod?.incrementType ?? ''} onChange={handlePeriodChange} >
              <option value="Day">Day</option>
              <option value="Week">Week</option>
              <option value="Month">Month</option>
              <option value="SemiMonth">Semi-Month</option>
            </SelectInput>
          </div>
          <div className="col-3 mb-3">
            <label className="form-label">{activePeriod?.incrementType == 'SemiMonth' ? 'Month Split Day' :'Increment'}</label>
            <NumericInput name="incrementAmount" value={activePeriod?.incrementAmount ?? ''} onChange={handlePeriodChange} />
          </div>
          <div className="col-6 mb-3">
            <label className="form-label">Volume Term</label>
            <QualificationSelect definitions={definitions} hideRank={true} emptyText="None" name="volumeKey" value={activePeriod?.volumeKey} onChange={handlePeriodChange} />
          </div>
          <div className="col-12 mb-1">
            <label className="form-label">Period Start Date</label>
            <DateTimeInput name="beginDate" value={activePeriod?.beginDate ?? ''} utc={false} onChange={handlePeriodChange} />
          </div>
          <small className="form-hint">Please note that the period start time will be different for different time zones.</small>
          <small className="form-hint">Local Time Zone: {Intl.DateTimeFormat().resolvedOptions().timeZone}</small>
        </div>

      </div>
      <div className="modal-footer">
        <button type="button" className="btn" data-bs-dismiss="modal">Close</button>
        <button type="button" className="btn btn-primary" onClick={handleSavePeriod} >Save</button>
      </div>
    </Modal>

    <Modal showModal={showCustomerType} onHide={handleHideCustomerType}>
      <div className="modal-header">
        <h5 className="modal-title">Customer Type</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <div className="row">
          <div className="col-4 mb-3">
            <label className="form-label">Customer Type</label>
            <NumericInput name="value" value={activeCustomerType?.value ?? ''} onChange={handleCustomerTypeChange} />
          </div>
          <div className="col-8 mb-3">
            <label className="form-label">Description</label>
            <TextInput name="description" value={activeCustomerType?.description ?? ''} onChange={handleCustomerTypeChange} />
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn" data-bs-dismiss="modal">Close</button>
        <button type="button" className="btn btn-primary" onClick={handleSaveCustomerType} >Save</button>
      </div>
    </Modal>

    <Modal showModal={showDelModal} size="sm" onHide={handleHideDelModal}>
      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      <div className="modal-body py-4">
        <div className="modal-title">Are you sure?</div>
        <div className="text-muted">Do you really want to remove this item? It cannot be undone.</div>
      </div>

      <div className="modal-footer">
        <div className="w-100">
          <div className="row">
            <div className="col">
              <button type="button" className="btn w-100" data-bs-dismiss="modal">Cancel</button>
            </div>
            <div className="col">
              <button type="button" className="btn btn-danger w-100" onClick={handleDeleteItem} >Delete</button>
            </div>
          </div>
        </div>
      </div>
    </Modal>

  </>
};

export default GeneralSettings;

GeneralSettings.propTypes = {
  customerTypes: PropTypes.any.isRequired,
  definitions: PropTypes.any.isRequired,
  periods: PropTypes.any.isRequired,
  description: PropTypes.string.isRequired,
  updateDescription: PropTypes.func.isRequired,
  updateCustomerTypes: PropTypes.func.isRequired,
  updatePeriods: PropTypes.func.isRequired
}
