import React from 'react';
import PropTypes from 'prop-types';
import TextInput from '../../../components/textInput';

const ScenarioValueInput = ({ step, stepType, onChange }) => {
  let tValue = undefined;

  if (stepType == "AddNode") {
    tValue = ["Customer Type", "Upline Node Id"]
  }

  if (stepType == "AddDownline") {
    tValue = ["Width", "Depth", "Customer Type"]
  }

  if (stepType == "AddSource") {
    tValue = ["SourceVolumeKey", "Value"]
  }

  if (stepType == "AddPlacement") {
    tValue = ["Upline Node Id", "Tree", "Upline Leg"]
  }
  
  if (stepType == "VerifyValue") {
    tValue = ["Value Code", "Value"]
  }

  if (stepType == "VerifyBonus") {
    tValue = ["Bonus Code", "Level", "Value"]
  }

  const handleParameterChange = ( name, value ) => {
    onChange(name, value);
  }

  return <>
      {tValue && tValue.map((input, index) => {
        return <div key={input} className="col">
          <label className="form-label">{input}</label>
          <TextInput name={`value${index+1}`} value={step[`value${index + 1}`]} onChange={handleParameterChange}  />
        </div>
      })}
  </>
};

export default ScenarioValueInput;

ScenarioValueInput.propTypes = {
  step: PropTypes.any.isRequired,
  stepType: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}