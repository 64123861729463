import React from 'react';
import { Children } from 'react';
import PropTypes from 'prop-types';

const PageHeader = ({ preTitle, title, children  }) => {

    let header;
    let content = [];

    Children.forEach(children, (child) => {
        if (!React.isValidElement(child)) return;
        if (child.type === CardHeader) {
          header = child;
        } else {
          content.push(child);
        }
    });


    return <div className="page-wrapper">
        <div className="container-xl">
            <div className="page-header d-print-none">
                <div className="row g-3 align-items-center">
                    <div className="col me-4">
                        <div className="page-pretitle">
                            {preTitle}
                        </div>
                        <h2 className="page-title">
                            {title}
                        </h2>
                    </div>
                    <div className="col-auto ms-auto d-print-none">
                        {!!header && <header>{header}</header>}
                    </div>
                </div>
            </div>
        </div>
        <div className="page-body">
        {content}
        </div>
    </div>
}

function CardHeader(props) {
    return <header>{props.children}</header>;
}

export default PageHeader;
export {CardHeader};

CardHeader.propTypes = {
    children: PropTypes.any.isRequired
}

PageHeader.propTypes = {
    preTitle: PropTypes.string,
    title: PropTypes.string.isRequired,
    children: PropTypes.any.isRequired
}
