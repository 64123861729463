import React from 'react';
import PropTypes from 'prop-types';

const DateTimeInput = ({ className, name, value, onChange, disabled, placeholder, utc = false }) => {
  const handleChange = (event) => {
    var name = event.target.name;
    var value = event.target.value;

    if (value == '') {
      onChange(name, value);
    } else {
      try {
        const date = new Date(value);
        if (utc) {
          const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes()));
          onChange(name, utcDate.toISOString());
        } else {
          onChange(name, date.toISOString());
        }
      } catch {
        //Do Nothing
      }
    }
  };

  let inputValue = '';
  let tValue = (value ?? '') == '' ? placeholder : value;
  if (tValue) {
    if (tValue && !tValue.endsWith("Z")) {
      tValue += "Z";
    }

    const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Parse the UTC time and convert it to the local time
    const dateObject = tValue ? new Date(tValue) : new Date();
    const localDate = utc ?
      new Date(dateObject.toLocaleString('en-US', { timeZone: "utc" })) :
      new Date(dateObject.toLocaleString('en-US', { timeZone: localTimezone }));

    // Format the local date as a string for the input value
    inputValue = formatDateTime(localDate);
  }

  var style = (value ?? '') == '' ? { color: '#a5a9b1' } : {};
  return <input type="datetime-local" className={className ?? 'form-control'} placeholder={placeholder ?? ''} name={name} value={inputValue} disabled={disabled} onChange={handleChange} autoComplete='off' style={style} />
}

const formatDateTime = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export default DateTimeInput;

DateTimeInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  utc: PropTypes.bool,
}
